import { currentUserVar } from "apollo/cache/auth";
import lodash from "lodash";
import { rankRoutes } from "react-location-rank-routes";
import { RouteProps } from "./routes";

export const withRouteRoles = (routes: RouteProps[]) => {
  const currentUser = currentUserVar();
  return lodash.filter(routes, (route) =>
    route?.withRoles && route?.withRoles.length > 0
      ? route?.withRoles.includes(currentUser?.role)
      : true
  );
};
/**
 *
 * @param permissions permissions associated with the current user. pass this if you are using permissions
 * @returns a list of filtered routes
 */
const filterRoutes = () => {
  return lodash.flow([rankRoutes, withRouteRoles]);
};

export default filterRoutes;
