import { gql, useQuery } from "@apollo/client";
import lodash from "lodash";

const GetInstallations = gql`
  query GetInstallationServiceOrdersSearch(
    $search: String
    $searchFields: [String!]
    $pageSize: Int
    $page: Int
  ) {
    items: getInstallationServiceOrders(
      search: $search
      searchFields: $searchFields
      pageSize: $pageSize
      page: $page
      sort: "-assignedAt"
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      servicePointCode
      status
      installationMeterSystemSyncStatus
      installationMeterCode
    }
  }
`;

const GetReplacements = gql`
  query GetReplacementServiceOrdersSearch(
    $search: String
    $searchFields: [String!]
    $pageSize: Int
    $page: Int
  ) {
    items: getReplacementServiceOrders(
      search: $search
      searchFields: $searchFields
      pageSize: $pageSize
      page: $page
      sort: "-assignedAt"
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      servicePointCode
      status
      installationMeterSystemSyncStatus
      installationMeterCode
    }
  }
`;

const GetBacklogs = gql`
  query GetBacklogInstallationServiceOrdersSearch(
    $search: String
    $searchFields: [String!]
    $pageSize: Int
    $page: Int
  ) {
    items: getBacklogInstallationServiceOrders(
      search: $search
      searchFields: $searchFields
      pageSize: $pageSize
      page: $page
      sort: "-assignedAt"
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      servicePointCode
      status
      installationMeterSystemSyncStatus
      installationMeterCode
    }
  }
`;

const GetRegularizations = gql`
  query GetRegularizationInstallationServiceOrdersSearch(
    $search: String
    $searchFields: [String!]
    $pageSize: Int
    $page: Int
  ) {
    items: getRegularizationInstallationServiceOrders(
      search: $search
      searchFields: $searchFields
      pageSize: $pageSize
      page: $page
      sort: "-assignedAt"
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      servicePointCode
      status
      installationMeterSystemSyncStatus
      installationMeterCode
    }
  }
`;

const GetMeters = gql`
  query GetMetersSearch(
    $search: String
    $searchFields: [String!]
    $pageSize: Int
    $page: Int
  ) {
    items: getMeters(
      search: $search
      searchFields: $searchFields
      pageSize: $pageSize
      page: $page
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      modelMeta {
        brandCode
        brandName
        modelCode
        modelName
        phase
        digits
      }
      modelType
      status
      location
    }
  }
`;

export const useZeusSearch = (search: string) => {
  const installation = useQuery(GetInstallations, {
    variables: {
      page: 1,
      pageSize: 8,
      searchFields: [
        "code",
        "installationMeterCode",
        "installedMeterCode",
        "servicePointCode",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
        "location.address",
        "location.community",
      ],
      search,
    },
  });
  const replacement = useQuery(GetReplacements, {
    variables: {
      page: 1,
      pageSize: 8,
      searchFields: [
        "code",
        "installationMeterCode",
        "installedMeterCode",
        "servicePointCode",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
        "location.address",
        "location.community",
      ],
      search,
    },
  });
  const regularization = useQuery(GetRegularizations, {
    variables: {
      page: 1,
      pageSize: 8,
      searchFields: [
        "code",
        "installationMeterCode",
        "installedMeterCode",
        "servicePointCode",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
        "location.address",
        "location.community",
      ],
      search,
    },
  });
  const backlog = useQuery(GetBacklogs, {
    variables: {
      page: 1,
      pageSize: 8,
      searchFields: [
        "code",
        "installationMeterCode",
        "installedMeterCode",
        "servicePointCode",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
        "location.address",
        "location.community",
      ],
      search,
    },
  });
  const meter = useQuery(GetMeters, {
    variables: {
      page: 1,
      pageSize: 8,
      searchFields: ["code"],
      search,
    },
  });

  return {
    installation: lodash.pick(installation, ["data", "loading"]),
    replacement: lodash.pick(replacement, ["data", "loading"]),
    regularization: lodash.pick(regularization, ["data", "loading"]),
    backlog: lodash.pick(backlog, ["data", "loading"]),
    meter: lodash.pick(meter, ["data", "loading"]),
  };
};
