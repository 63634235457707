import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";
import { currentConfigVar } from "apollo/cache/config";

interface MeterModelPickerContainerProps {
  filter?: {
    brand?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  rawId?: boolean;
}

const GET_METER_MODELS = gql`
  query GetMeterModelsSelect($brand: ID) {
    meterModels: getMeterModels(sort: "name" brand: $brand page: 0 pageSize: 0) {
      _id
      code
      name
    }
  }
`;

const MeterModelPickerContainer: FC<MeterModelPickerContainerProps> = ({ filter, id, label, rawId, ...form }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const { loading, data } = useQuery(GET_METER_MODELS, {
    variables: {
      ...(filter ? filter : {})
    },
    notifyOnNetworkStatusChange: false,
    pollInterval
  });

  return (
    <SearchSelectInput
      id={id ?? "model"}
      label={label ?? "Meter Model"}
      placeholder="Select Meter Model"
      optionsLoading={loading}
      options={(data?.meterModels ?? [])?.map((meterModel: any) => ({
        label: {
          title: `${meterModel.name}`,
        },
        value: rawId ? meterModel?._id : meterModel
      }))}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default MeterModelPickerContainer;