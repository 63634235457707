import { useEffect } from "react"
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, DisapproveServiceOrderForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_SERVICE_ORDER } from "./view";
import { DisapproveServiceOrderFormSchema } from "components/forms/disapprove-service-order/schema";


const DISAPPROVE_SERVICE_ORDER = gql`
  mutation DisapproveInstallationServiceOrder(
    $id: ID!
    $reason: String!
  ) {
    disapproveInstallationServiceOrder(
      id: $id
      reason: $reason
    ) {
      _id
    }
  }
`

export default function DisapproveInstallationServiceOrderContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const [disapproveInstallationServiceOrder] = useMutation(DISAPPROVE_SERVICE_ORDER)

  const form = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: DisapproveServiceOrderFormSchema,
    onSubmit: async (values) => {
      await disapproveInstallationServiceOrder({
        variables: {
          id: searchParams.id,
          ...values
        }
      }).then(({ data }) => {
        if (data.disapproveInstallationServiceOrder._id) {
          toast(JSON.stringify({ type: "success", title: "Service Order Disapproveed Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not disapprove Service Order" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Disapprove Service Order"
      size="4xl"
      description="Provide reasons for disapproving service order"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Disapproving Service Order..." : "Disapprove Service Order"}
          </button>
        </>
      )}
    >
      {data?.installationServiceOrder?._id && (
        <DisapproveServiceOrderForm
          form={form}
        />
      )}
    </Modal>
  )
}