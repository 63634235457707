import { gql } from "@apollo/client";

export const GET_SERVICE_ORDERS = gql`
  query GetInstallationServiceOrders(
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $status: InstallationServiceOrderStatus
    $category: InstallationServiceOrderCategory
    $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
    $farmingOutContractor: ID
    $farmingOutContractorUser: ID
  ) {
    rows: getInstallationServiceOrders(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      district: $district
      status: $status
      category: $category
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      contractorAssigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      farmingOutContractor {
        _id
        code
        name
      }
      farmingOutContractorUser {
        _id
        code
        username
        lastName
        firstName
      }
      category
      status
      priority
      estimatedResolutionDate
      servicePointCode
      servicePoint {
        _id
        code
        geoCode
      }
      request {
        _id
        code
        type
        inspection {
          resolution {
            service {
              meterPhase
              tariffClass {
                _id
                code
                name
              }
            }
          }
        }
      }
      installationType {
        _id
        code
        name
      }
      installedMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
        }
        system {
          _id
          code
          name
        }
        meterCode
      }
      installationMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
        }
        system {
          _id
          code
          name
        }
        meterCode
      }
      installationMeterSystemCode
      installationMeterSystemSyncStatus
      installationMeterSystemSyncMethod
      assignedAt
      startedAt
      resolvedAt
      completedAt
      reverseSyncedAt
      rejectedAt
      createdAt
      updatedAt
    }
    count: getInstallationServiceOrdersCount(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      search: $search
      searchFields: $searchFields
      district: $district
      status: $status
      category: $category
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    )
  }
`;

export const GET_SERVICE_ORDER_SUMMARY = gql`
  query GetInstallationServiceOrderSummary(
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $category: InstallationServiceOrderCategory
    $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
    $farmingOutContractor: ID
    $farmingOutContractorUser: ID
  ) {
    summary: getInstallationServiceOrderSummary(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      search: $search
      searchFields: $searchFields
      district: $district
      category: $category
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    ) {
      AssignedToSupplier
      AssignedToInstaller
      Resolved
      InProgress
      Disapproved
      Completed
    }
  }
`;

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query(
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $status: InstallationServiceOrderStatus
    $category: InstallationServiceOrderCategory
    $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
    $farmingOutContractor: ID
    $farmingOutContractorUser: ID
  ) {
    url: getInstallationServiceOrderExportUrl(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      search: $search
      searchFields: $searchFields
      district: $district
      status: $status
      category: $category
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    )
  }
`;

export const GET_SERVICE_WORK_ORDERS = gql`
  query GetInstallationServiceOrderAssignments(
    $fromDate: Date
    $toDate: Date
    $district: ID
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $status: InstallationServiceOrderAssignmentStatus
  ) {
    rows: getInstallationServiceOrderAssignments(
      fromDate: $fromDate
      toDate: $toDate
      district: $district
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      status: $status
    ) {
      _id
      code
      status
      district {
        _id
        code
        name
      }
      meta {
        totalSuccessAssignments
        totalFailedAssignments
        totalAssignments
      }
      createdBy {
        _id
        lastName
        firstName
        phoneNumber
        profileImageUrl
      }
      createdAt
      updatedAt
    }
    count: getInstallationServiceOrderAssignmentsCount(
      fromDate: $fromDate
      toDate: $toDate
      district: $district
      search: $search
      searchFields: $searchFields
      status: $status
    )
  }
`;
