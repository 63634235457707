import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { TextArea } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

interface DisapproveServiceOrderFormProps {
  form: FormikProps<{
    reason: string;
  }>;
}

const DisapproveServiceOrderForm: FC<DisapproveServiceOrderFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Disapproval Information</span>
        <div className="grid gap-4 mt-2">
          <div>
            <TextArea
              id="reason"
              label="Disapproval Reason"
              placeholder="e.g. Details captured are inconclusive"
              required={true}
              rows={10}
              {...form}
            />
          </div>

          <div className="rounded-md bg-amber-50 border border-amber-100 p-3">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-amber-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-2">
                <h3 className="text-sm font-medium text-amber-800">
                  Attention needed
                </h3>
                <div className="mt-1 space-y-2 text-sm text-amber-700">
                  <p>
                    Disapproving this service order would make it available for the farming out contractor to provide materials details again. The status of the order would be <span  className="text-sm font-medium text-amber-800">Disapproved</span>.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisapproveServiceOrderForm