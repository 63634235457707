import { Avatar } from "components/core";

const ContractorUserInfoSummary = ({ data }: any) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
    <div className='grid grid-cols-4 gap-4'>
      <div className='row-span-2 flex flex-col space-y-3 items-center justify-center'>
        <Avatar
          alt={data?.contractorUser?.fullName || "N A"}
          src={data?.contractorUser?.profileImageUrl || ""}
          size="xl"
        />
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
            Agent Code
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.contractorUser?.code || "N/A"}
        </div>
      </div>
      <div className=''>
        <span className="block text-sm font-light text-gray-700">
            First Name
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.contractorUser?.firstName || "N/A"}
        </div>
      </div>
      <div className=''>
        <span className="block text-sm font-light text-gray-700">
            Last Name
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.contractorUser?.lastName || "N/A"}
        </div>
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
            AMC Username
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.contractorUser?.username || "N/A"}
        </div>
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
            Phone Number
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.contractorUser?.phoneNumber || "N/A"}
        </div>
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
            Email Address
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.contractorUser?.emailAddress || "N/A"}
        </div>
      </div>
    </div>
  </div>
);

export default ContractorUserInfoSummary;