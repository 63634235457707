import lodash from "lodash";
import numeral from "numeral";
import { FC } from "react";

interface MeterViewProps {
  meter: {
    _id: string;
    code: string;
    batchCode: string;
    brand: {
      _id: string;
      code: string;
      name: string;
    };
    model: {
      _id: string;
      code: string;
      name: string;
      phase: number;
      type: string;
      compliance: string;
      digits: number;
      voltage: number;
      currentRating: number;
      mechanism: string;
      communicationType: string;
      createdAt: string;
      updatedAt: string;
    };
    servicePoint: {
      _id: string;
      code: string;
      qrCode: string;
      geoLocation: {
        type: string;
        coordinates: [number, number];
      };
      property: {
        _id: string;
        code: string;
        qrCode: string;
        address: string;
        ghanaPostAddress: string;
        streetName: string;
        houseNumber: string;
        houseNumberSlateUrl: string;
        regionCode: string;
        regionName: string;
        districtCode: string;
        districtName: string;
        blockCode: string;
        blockName: string;
        roundCode: string;
        roundName: string;
        plotCode: string;
        community: string;
        itineraryCode: string;
        itineraryDescription: string;
      };
      customer: {
        _id: string;
        code: string;
        customerType: string;
      
        company: {
          name: string;
        };
        representative: {
          title: string;
          fullName: string;
          nationality: string;
          dateOfBirth: string;
          gender: string;
          phoneNumber: string;
          emailAddress: string;
          profileImageUrl: string;
        };
        status: string;
      };
      account: {
        _id: string;
        code: string;
        status: string;
        balance: number;
        balanceUpdatedAt: string;
        meta: {
          lastPaymentAmount: number;
          lastPaymentDate: string;
          lastBillAmount: number;
          lastBillDate: string;
          lastReadingValue: number;
          lastReadingDate: string;
        };
      };
      serviceType: string;
      serviceClass: string;
      tariffClass: {
        _id: string;
        code: string;
        name: string;
      };
      geoCode: string;
      transformerPhase: string;
      meterLocation: string;
      meterHeight: number;
      transformerNumber: string;
      currentTransformerRatio: number;
      deliveryPointNumber: string;
      contractedDemand: number;
      poleNumber: string;
      energyCertificateNumber: string;
      energyCertificateDocumentUrl: string;
      activity: {
        _id: string;
        code: string;
        name: string;
      };
      subActivity: {
        _id: string;
        code: string;
        name: string;
      };
      appliances: {
        appliance: {
          _id: string;
          code: string;
          name: string;
          wattage: number;
        };
        quantity: number;
      }[];
      status: string;
      meta: {
        lastBillAmount: number;
        lastBillDate: string;
        lastReadingValue: number;
        lastReadingDate: string;
      };
    };
    status: string;
    balance: number;
    balanceUpdatedAt: string;
    meta: {
      lastReadingValue: number;
      lastReadingDate: string;
    };
    location: string;
    region: {
      _id: string;
      code: string;
      name: string;
    };
    district: {
      _id: string;
      code: string;
      name: string;
    };
    createdAt: string;
    updatedAt: string;
  };
}

const MeterView: FC<MeterViewProps> = ({ meter }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Meter Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.code || "N/A"}
            </div>
          </div>

          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Batch Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.batchCode || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Location
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.startCase(meter?.location) || "N/A"}
            </div>
          </div>

          {meter.region && (
            <div>
              <span className="block text-sm font-light text-gray-700">
                Region
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meter?.region?.name || "N/A"}
              </div>
            </div>
          )}

          {meter.district && (
            <div>
              <span className="block text-sm font-light text-gray-700">
                District
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meter?.district?.name || "N/A"}
              </div>
            </div>
          )}

          {meter.district && (
            <div>
              <span className="block text-sm font-light text-gray-700">
                Meter Status
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meter?.status || "N/A"}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Meter Model Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Brand
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.brand?.name || "N/A"}
            </div>
          </div>

          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Model Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.model?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Model Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.model?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div>
        <span className="text-xs font-light">
          Meter Model Specification Information
        </span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.model?.type || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Communication Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.startCase(meter?.model?.communicationType) || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Compliance
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.model?.compliance || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Phase
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.model?.phase || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Mechanism
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.model?.mechanism || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Digits
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.model?.digits || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Voltage
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.model?.voltage || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Current Rating
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meter?.model?.currentRating || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterView;
