import { gql, useQuery } from "@apollo/client";
import { MeterModelView, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

export const GET_METER_MODEL = gql`
  query GetMeterModel($id: ID!) {
    meterModel: getMeterModel(id: $id) {
      _id
      code
      digits
      type
      phase
      createdAt
      updatedAt
      brand {
        _id
        code
        name
      }
      system {
        _id
        code
        name
      }
      contractor {
        _id
        code
        name
      }
      communicationType
      currentRating
      mechanism
      name
      voltage
    }
  }
`;


export default function ViewMeterModelContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_METER_MODEL, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      title="Meter Model Information"
      description="Details of meter model are shown below"
    >
      <MeterModelView meterModel={data?.meterModel} />
    </Modal>
  );
}
