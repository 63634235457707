import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, Shimmers, TableComponent } from "components";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  withRoles,
  classNames,
  useTableData,
} from "utils";
import config from "config";
import CreateMeterShipmentContainer from "./create";
import ViewMeterShipmentContainer from "./view";
import { Avatar } from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import { ExportDataContainer } from "containers";
import numeral from "numeral";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";

const GET_METER_SHIPMENTS = gql`
  query GetMeterShipments(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    rows: getMeterShipments(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
      _id
      code
      description
      contractorCreatedBy {
        _id
        code
        emailAddress
        firstName
        gender
        ghanaCardNumber
        lastName
        phoneNumber
        profileImageUrl
      }
      createdAt
      updatedAt
      endSerialNumber
      quantity
      startSerialNumber
      status
      model {
        _id
        code
        name
        type
        brand {
          _id
          code
          name
        }
      }
    }
    count: getMeterShipmentsCount(search: $search, searchFields: $searchFields)
  }
`;

const MeterShipmentsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: [ "description"],
      sort: searchParams.sort || "",
      status: searchParams.orderStatus || undefined,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_METER_SHIPMENTS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <Header
        renderActions={() => (
          <>
            {withRoles(["Super", "Admin"])(
              <button
                type="button"
                onClick={wrapClick(() => setModal("create"))}
                className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <DocumentPlusIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                Add New Shipment
              </button>
            )}
          </>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"meter shipments"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="MeterShipment"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Brand
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Model
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Start Serial No
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  End Serial No.
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Created By
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-500">
                    {moment(item?.createdAt).format(dateFormat + ", hh:mm A")}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.model?.brand?.name || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.model?.name || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.startSerialNumber}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.endSerialNumber}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="text-gray-500 dark:text-gray-500">
                    {numeral(item?.quantity).format("0,0") || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.contractorCreatedBy?.lastName || "")?.trim(),
                            (
                              item?.contractorCreatedBy?.firstName || ""
                            )?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.contractorCreatedBy?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {[
                          (item?.contractorCreatedBy?.lastName || "")?.trim(),
                          (item?.contractorCreatedBy?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.contractorCreatedBy?.code || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      item?.status === "Pending"
                        ? "bg-amber-100 text-amber-800"
                        : "",
                      item?.status === "Approved"
                        ? "bg-green-100 text-green-800"
                        : "",
                      !item?.status ? "bg-gray-200 text-gray-800" : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.status || "Unknown"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      <CreateMeterShipmentContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={refetch}
      />
      {!!searchParams.id?.length && (
        <>
          <ViewMeterShipmentContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default MeterShipmentsPage;
