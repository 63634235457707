import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { TextArea } from "components/core";
import { FormikProps } from "formik";
import { FC, useMemo } from "react";

interface ApproveServiceOrderFormProps {
  form: FormikProps<{
    notes: string;
  }>;
  installationSyncMethod: string;
  installationMeter: any;
  reverseSyncMeter: any;
}

const ApproveServiceOrderForm: FC<ApproveServiceOrderFormProps> = ({
  form,
  installationMeter,
  reverseSyncMeter,
  installationSyncMethod,
}) => {
  const isWarning = useMemo(
    () => reverseSyncMeter?._id !== installationMeter?._id,
    [installationMeter, reverseSyncMeter]
  );
  const isNoReverseSync = useMemo(
    () => !reverseSyncMeter?._id,
    [reverseSyncMeter]
  );

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Approval Information</span>
        <div className="grid gap-4 mt-2">
          <div>
            <TextArea
              id="notes"
              label="Notes"
              placeholder="e.g. Details captured are conclusive"
              required={true}
              rows={10}
              {...form}
            />
          </div>
          {isNoReverseSync && installationSyncMethod === "ReverseSync" && (
            <div className="rounded-md bg-amber-50 border border-amber-100 p-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-amber-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-2">
                  <h3 className="text-sm font-medium text-amber-800">
                    Attention needed
                  </h3>
                  <div className="mt-1 space-y-2 text-sm text-amber-700">
                    <p>
                      Reverse Sync from AMC is currently not in, approving this service order would submit order through the new process.<br/>It is adviceable to wait for Reverse Sync if your FOC would be commissioning using the Simens App
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isWarning && installationSyncMethod === "ReverseSync" && !isNoReverseSync && (
            <div className="rounded-md bg-amber-50 border border-amber-100 p-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-amber-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-2">
                  <h3 className="text-sm font-medium text-amber-800">
                    Attention needed
                  </h3>
                  <div className="mt-1 space-y-2 text-sm text-amber-700">
                    <p>
                      Meter assigned is not the same as meter installed on
                      prepayment system. Expected meter{" "}
                      <span className="text-sm font-medium text-amber-800">
                        {installationMeter?.code}
                      </span>{" "}
                      (model: {installationMeter?.modelMeta?.brandName} -{" "}
                      {installationMeter?.modelMeta?.modelName}) but got{" "}
                      <span className="text-sm font-medium text-amber-800">
                        {reverseSyncMeter?.code}
                      </span>{" "}
                      (model: {reverseSyncMeter?.modelMeta?.brandName} -{" "}
                      {reverseSyncMeter?.modelMeta?.modelName})
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isWarning && installationSyncMethod === "ReverseSync" && !isNoReverseSync && (
            <div className="rounded-md bg-amber-50 border border-amber-100 p-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-amber-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-2">
                  <h3 className="text-sm font-medium text-amber-800">
                    Attention needed
                  </h3>
                  <div className="mt-1 space-y-2 text-sm text-amber-700">
                    <p>
                      Approving this service order would override assigned meter
                      with meter installed on the prepayment system. This means
                      that, meter{" "}
                      <span className="text-sm font-medium text-amber-800">
                        {reverseSyncMeter?.code}
                      </span>{" "}
                      (model: {reverseSyncMeter?.modelMeta?.brandName} -{" "}
                      {reverseSyncMeter?.modelMeta?.modelName}) would be
                      installed in place of{" "}
                      <span className="text-sm font-medium text-amber-800">
                        {installationMeter?.code}
                      </span>{" "}
                      (model: {installationMeter?.modelMeta?.brandName} -{" "}
                      {installationMeter?.modelMeta?.modelName}).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApproveServiceOrderForm;
