import { FC } from "react";
import { wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { ContractorUserForm, Modal } from "components";
import { ContractorUserSchema } from "components/forms/contractor-user/schema";

const CREATE_CONTRACTOR_USER = gql`
  mutation CreateContractorUser(
    $username: String!
    $lastName: String!
    $firstName: String!
    $ghanaCardNumber: String
    $phoneNumber: String!
    $emailAddress: String
    $profileImageUrl: String
    $contractor: ID
  ) {
    createContractorUser(
      username: $username
      lastName: $lastName
      firstName: $firstName
      ghanaCardNumber: $ghanaCardNumber
      phoneNumber: $phoneNumber
      emailAddress: $emailAddress
      profileImageUrl: $profileImageUrl
      contractor: $contractor
    ) {
      _id
    }
  }
`;

interface CreateContractorUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  contractor: string;
}

const CreateContractorUserContainer: FC<CreateContractorUserContainerProps> = ({
  open,
  setOpen,
  refetch,
  contractor,
}) => {
  const [createContractorUser, { loading }] = useMutation(
    CREATE_CONTRACTOR_USER
  );

  const form = useFormik<any>({
    initialValues: {
      username: "",
      lastName: "",
      firstName: "",
      ghanaCardNumber: "",
      phoneNumber: "",
      emailAddress: "",
      profileImageUrl: "",
      role: "Agent",
    },
    onSubmit: async (values) => {
      await createContractorUser({
        variables: {
          ...values,
          contractor,
        },
      }).then(({ data }) => {
        if (data.createContractorUser._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "User Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({ type: "error", title: "Could not create User" })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Add New Contractor Agent'
      description='Provide the details to add a new farming out contractor agent'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Agent..." : "Add Agent"}
          </button>
        </>
      )}
    >
      <ContractorUserForm form={form} />
    </Modal>
  );
};

export default CreateContractorUserContainer;
