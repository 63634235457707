import { gql, useQuery } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";

interface ContractorUserPickerContainerProps {
  filter?: {
    contractor?: string;
    district?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
  position?: "top" | "bottom";
}

const GET_CONTRACTOR_USERS = gql`
  query GetContractorUsersSelect($contractor: ID) {
    contractorUsers: getContractorUsers(
      contractor: $contractor
      sort: "code"
      page: 0
      pageSize: 0
    ) {
      _id
      code
      username
      firstName
      lastName
      phoneNumber
      emailAddress
    }
  }
`;

const ContractorUserPickerContainer: FC<ContractorUserPickerContainerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const { loading, data } = useQuery(GET_CONTRACTOR_USERS, {
    variables: {
      ...(filter ? filter : {}),
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <SearchSelectInput
      id={id ?? "contractorUser"}
      label={label ?? "Contractor Agent"}
      placeholder="Select Agent"
      optionsLoading={loading}
      options={(data?.contractorUsers ?? [])?.map((contractorUser: any) => ({
        label: {
          title: `${contractorUser.firstName} ${contractorUser.lastName}  (${contractorUser.code} )`,
        },
        value: rawId ? contractorUser._id : contractorUser,
      }))}
      {...form}
    />
  );
};

export default ContractorUserPickerContainer;
