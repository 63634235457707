import ContractorInfoForm from "./contractor-info-form";
import ContractorInfoSummary from "./contractor-info-summary";
import ContractorUserInfoForm from "./contractor-user-info-form";
import ContractorUserInfoSummary from "./contractor-user-info-summary";
import MeterInfoForm from "./meter-info-form";
import MeterInfoSummary from "./meter-info-summary";

const formSteps = [
  {
    name: "Contractor Info",
    description: "Information about contractor to attend to service order.",
    accessor: "contractorInfo",
    FormComponent: ContractorInfoForm,
    SummaryComponent: ContractorInfoSummary,
  },
  {
    name: "Contractor Agent Info",
    description: "Information about account in AMC to attend to service order.",
    accessor: "contractorUserInfo",
    FormComponent: ContractorUserInfoForm,
    SummaryComponent: ContractorUserInfoSummary,
  },
  {
    name: "Meter Info",
    description: "Information about meter to be installed.",
    accessor: "meterInfo",
    FormComponent: MeterInfoForm,
    SummaryComponent: MeterInfoSummary,
  },
];

export default formSteps;
