import { wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MeterShipmentForm } from "components";
import * as yup from "yup";

const CREATE_METER_SHIPMENT = gql`
  mutation CreateContractorMeterShipment(
    $code: String!
    $description: String!
    $model: ID!
    $meterSystemDetails: [MeterShipmentMeterDetailInput!]!
  ) {
    createContractorMeterShipment(
      code: $code
      description: $description
      model: $model
      meterSystemDetails: $meterSystemDetails
    ) {
      _id
    }
  }
`;

// const schema = yup.object().shape({
//   shipmentInfo: yup.object().shape({
//     brand: yup.object().notRequired(),
//     model: yup.object().notRequired(),
//     code: yup.string().notRequired(),
//     description: yup.string().notRequired(),
//     serialNumbers: yup.array().of(yup.string().notRequired()),
//   }),
//   metersInfo: yup.object().shape({
//     meterSystemDetails: yup.array().of(
//       yup.object().shape({
//         meterSystem: yup.object().required("Required"),
//         meterType: yup.object().required("Required"),
//         meterCount: yup.number().required("Required"),
//         meterStartNumber: yup.number().required("Required"),
//         meterEndNumber: yup.number().required("Required"),
//         // llsSecret: yup.string().required("Required"),
//       })
//     ),
//   }),
// });

export default function CreateMeterShipmentContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createContractorMeterShipment, { loading }] = useMutation(
    CREATE_METER_SHIPMENT
  );

  const form = useFormik({
    initialValues: {
      shipmentInfo: {
        brand: null as any,
        model: null as any,
        code: "",
        description: "",
        serialNumbers: [] as string[],
      },
      metersInfo: {
        meterSystemDetails: [] as any[],
      },
    },
    // validationSchema: schema,
    onSubmit: async (values) => {
      await createContractorMeterShipment({
        variables: {
          ...values.shipmentInfo,
          model: values.shipmentInfo.model._id,
          ...values.metersInfo,
        },
      }).then(({ data }) => {
        if (data.createContractorMeterShipment._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Shipment Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Shipment",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Meter Shipment"
      description="Provide the details to add a new shipment"
      hideActions
      hidePadding
      size="6xl"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Shipment..." : "Add Shipment"}
          </button>
        </>
      )}
    >
      <MeterShipmentForm form={form} />
    </Modal>
  );
}
