import * as Yup from "yup";

export const ContractorInfoFormSchema = Yup.object().shape({
  contractor: Yup.object().required(),
});

export const ContractorUserInfoFormSchema = Yup.object().shape({
  contractorUser: Yup.object().required(),
});

export const MeterInfoFormSchema = Yup.object().shape({
  meter: Yup.object().required(),
});

export const AssignServiceOrderFormSchema = Yup.object().shape({
  contractorInfo: ContractorInfoFormSchema,
  contractorUserInfo: ContractorUserInfoFormSchema,
  meterInfo: MeterInfoFormSchema,
});
