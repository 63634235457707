import { FC } from "react";
import { classNames } from "utils";
import lodash from "lodash";

interface AvatarProps {
  status: string;
  options: Record<string, string>;
}

const StatusBadge: FC<AvatarProps> = ({ status, options }) => {
  return (
    <span
      className={classNames(
        "bg-gray-100 text-gray-800",
        options?.[status] || "",
        !status ? "bg-gray-200 text-gray-800" : "",
        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 10"
        fill="currentColor"
        className="w-1.5 h-1.5"
      >
        <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
      </svg>
      <span>{lodash.startCase(status || "Unknown")}</span>
    </span>
  );
};

export default StatusBadge;
