import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { currentUserVar, setMe } from "apollo/cache/auth";
import { TextInput, SelectInput, AvatarUpload } from "components/core";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import toast from "react-hot-toast";
import { classNames } from "utils";

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $lastName: String
    $firstName: String
    $profileImageUrl: String
  ) {
    updateUser(
      id: $id
      lastName: $lastName
      firstName: $firstName
      profileImageUrl: $profileImageUrl
    ) {
      _id
    }
  }
`;

const SettingsPersonalContainer: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const [updateUser, { loading }] = useMutation(UPDATE_USER);

  const form = useFormik({
    initialValues: {
      lastName: "",
      firstName: "",
      profileImageUrl: "",
    },
    onSubmit: async (values) => {
      await updateUser({
        variables: {
          id: currentUser?._id,
          ...values
        }
      }).then(({ data }) => {
        if (data.updateUser._id) {
          toast(JSON.stringify({ type: "success", title: "Profile Edited Successfully" }));
          setMe({
            ...currentUser,
            ...values,
          });
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not update Profile" }));
        }
      });
    },
  });

  useEffect(() => {
    form.setValues({
      lastName: currentUser?.lastName || "",
      firstName: currentUser?.firstName || "",
      profileImageUrl: currentUser?.profileImageUrl || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <form onSubmit={form.handleSubmit} onReset={form.handleReset}>
      <div className="overflow-hidden shadow sm:rounded-md">
        <div className="bg-white px-4 py-5 sm:p-6">
          <div className='grid grid-cols-2 gap-6'>

            <div>
              <AvatarUpload
                id='profileImageUrl'
                label='Photo'
                {...form}
              />
            </div>
            <div />

            <div>
              <TextInput
                id="lastName"
                label="Last Name"
                type="text"
                placeholder='e.g. Mensah'
                {...form}
              />
            </div>

            <div>
              <TextInput
                id="firstName"
                label="Others Names"
                type="text"
                placeholder='e.g. Enoch Nana Nyankah'
                {...form}
              />
            </div>

            <div>
              <TextInput
                id="phoneNumber"
                label="Phone Number"
                type="text"
                placeholder='e.g. 0550123292'
                disabled={true}
                values={currentUser}
                handleChange={undefined}
                handleBlur={undefined}
              />
            </div>

            <div>
              <TextInput
                id="emailAddress"
                label="Email Address"
                type="email"
                placeholder='e.g. nyankahmensah@gmail.com'
                disabled={true}
                values={currentUser}
                handleChange={undefined}
                handleBlur={undefined}
              />
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 space-x-3">
          <button
            type="reset"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className={classNames(
              loading ? "cursor-not-allowed" : "cursor-pointer",
              "inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            )}
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SettingsPersonalContainer;