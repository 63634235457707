import { useMemo, useState } from "react";
import { classNames, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import {
  InstallationServiceOrderResolutionView,
  Modal,
  ServiceOrderHistoryView,
  InstallationServiceOrderPrepaymentView,
  RegularizationInstallationServiceOrderView,
  ReplacementServiceOrderResolutionView,
} from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { Action } from "components/buttons/action-button";
import { Disclosure } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";

export const GET_SERVICE_ORDER = gql`
  fragment ServiceOrderPrepaidSync on PrepaidSync {
    _id
    status
    stage
    completedAt
    data {
      servicePointCode
      regionCode
      customer {
        surname
        otherNames
        idNo
        idType
        taxRefNo
        address1
        address2
        address3
        telephone1
        telephone2
        telephone3
        fax
        email
      }
      servicePoint {
        address1
        address2
        address3
        geoCode
        digitalAddress
      }
      tariffClass {
        tariffCode
        tariffGroup
      }
      meter {
        meterSerialNo
        batchNo
        make
        model
        phase
        systemDetails
      }
      debt {
        debtType
        debtAmount
        debtRef
        instalmentDueDate
        debtStatus
      }
    }
    history {
      action
      data
      message
      timestamp
      status
    }
  }

  fragment MeterDetail on Meter {
    _id
    code
    modelMeta {
      brandCode
      brandName
      systemCode
      systemName
      modelCode
      modelName
      phase
    }
    syncMethod
  }

  query GetInstallationServiceOrder($id: ID!) {
    installationServiceOrder: getRegularizationInstallationServiceOrder(
      id: $id
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      servicePointCode
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community 
      }
      request {
        _id
        code
        meterCode
        billing {
          neighbourhoodClass
          numberOfRooms
          meterImageUrl
          meterNumber
        }
        createdAt
        inspection {
          resolution {
            property {
              propertyImageUrls
            }
            service {
              meterPhase
              tariffClass {
                _id
                code
                name
              }
            }
            materials {
              material {
                _id
                code
                name
                quantityUnit
              }
              quantity
            }
            recoveryData {
              meterNumber
              meterModel
              meterBrand
              meterImageUrl
              meterCondition
              meterDescription
            }
          }
        }
      }
      installationType {
        _id
        code
        name
      }
      meterContractor {
        _id
        code
        name
        contactPerson {
          emailAddress
          fullName
          phoneNumber
        }
      }
      farmingOutContractorUser {
        _id
        code
        username
        firstName
        lastName
        phoneNumber
        emailAddress
      }
      farmingOutContractor {
        _id
        code
        name
        ghanaPostAddress
        contactPerson {
          emailAddress
          fullName
          phoneNumber
        }
      }
      assigneeType
      assigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      resolution {
        recoveryData {
          meterNumber
          meterModel
          meterBrand
          meterImageUrl
          meterCondition
          meterDescription
        }
        recoveredMaterials {
          quantity
          material {
            _id
            code
            name
            quantityUnit
            quantity
            updatedAt
            category
          }
        }
        currentReading {
          readingDate
          readingImageUrl
          readingValue
        }
        previousReading {
          readingDate
          readingImageUrl
          readingValue
        }
        property {
          poleNumber
          propertyImageUrls
        }
        service {
          qrCode
          meter {
            ...MeterDetail
          }
        }
        reverseSyncMeter {
          ...MeterDetail
        }
        reverseSyncReading {
          readingDate
          readingValue
        }

        notes
        materials {
          quantity
          material {
            _id
            code
            name
            quantityUnit
            quantity
            updatedAt
            category
          }
        }
      }
      status
      result
      priority
      history {
        actor {
          ... on Contractor {
            _id
            name
            code
          }
          ... on ContractorUser {
            _id
            firstName
            lastName
            username
            code
          }
          ... on MeterContractor {
            _id
            name
            code
          }
          ... on MeterContractorUser {
            _id
            firstName
            lastName
            code
          }
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        assignee {
          ... on Contractor {
            _id
            name
            code
          }
          ... on ContractorUser {
            _id
            firstName
            lastName
            username
            code
          }
          ... on MeterContractor {
            _id
            name
            code
          }
          ... on MeterContractorUser {
            _id
            firstName
            lastName
            code
          }
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        actorType
        assigneeType
        timestamp
        action
        notes
      }
      approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      disapprover {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }

      notes
      estimatedResolutionDate
      installationMeter {
        ...MeterDetail
      }
      installedMeter {
        ...MeterDetail
      }
      installationMeterSystemSyncStatus
      installationMeterSystemSyncMethod
      installationSync {
        ...ServiceOrderPrepaidSync
      }
      presetAmountSync {
        ...ServiceOrderPrepaidSync
      }
      installationMeterSystemSyncStatus
      assignedAt
      contractorAssignedAt
      startedAt
      resolvedAt
      contractorResolvedAt
      completedAt
      disapprovedAt

      createdAt
      updatedAt
    }
  }
`;

const orderTabs = [
  {
    name: "Order Details",
    href: "OrderDetails",
    activeStatues: [
      "AssignedToSupplier",
      "AssignedToInstaller",
      "Resolved",
      "Completed",
      "Disapproved",
      "InProgress",
    ],
  },
  {
    name: "Order History",
    href: "OrderHistory",
    activeStatues: [
      "AssignedToSupplier",
      "AssignedToInstaller",
      "Resolved",
      "Disapproved",
      "Completed",
      "InProgress",
    ],
  },
  {
    name: "Prepayment Details",
    href: "PrepaymentDetails",
    activeStatues: [
      "AssignedToInstaller",
      "Resolved",
      "InProgress",
      "Disapproved",
      "Completed",
    ],
  },
  {
    name: "Resolution Details",
    href: "ResolutionDetails",
    activeStatues: ["Resolved", "Disapproved", "Completed"],
  },
];

export default function ViewRegularizationInstallationServiceOrderContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("OrderDetails");
  const { data, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });
  const dispatchAction =
    (action: Exclude<Action, "expand" | "goto" | "clone">) => () => {
      navigate({
        search: (old) => ({
          ...old,
          modal: action,
        }),
      });
    };
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  const approveDisabled = useMemo(
    () =>
      data?.installationServiceOrder?.installationMeterSystemSyncMethod ===
        "ReverseSync" && !data?.installationServiceOrder?.reverseSyncedAt,
    [
      data?.installationServiceOrder?.installationMeterSystemSyncMethod,
      data?.installationServiceOrder?.reverseSyncedAt,
    ]
  );

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      hideActions={false}
      hidePadding={true}
      loading={loading}
      title="Service Order Information"
      size="4xl"
      description="Details of service order are shown below"
      renderActions={() => (
        <>
          {["AssignedToSupplier", "AssignedToInstaller", "InProgress"].includes(
            data?.installationServiceOrder?.status
          ) && (
            <>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={wrapClick(dispatchAction("assign"))}
              >
                {data?.installationServiceOrder?.status === "AssignedToSupplier"
                  ? "Assign"
                  : "Reassign"}
              </button>
            </>
          )}
          {["Resolved"].includes(data?.installationServiceOrder?.status) && (
            <>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={wrapClick(dispatchAction("disapprove"))}
              >
                Disapprove
              </button>
              <button
                type="button"
                data-tooltip-delay-show={0}
                data-tooltip-id="approve-tooltip"
                data-tooltip-hidden={!approveDisabled}
                data-tooltip-html={`
                <div className="flex items-center gap-x-6 bg-yellow-500 px-6 py-2.5">
                  <p className="text-sm leading-6 text-white">
                    Reverse Sync Information Is Not In Yet, Kindly Resolve Worktask In AMC
                  </p>
                </div>
        `}
                className={classNames(
                  "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                )}
                onClick={wrapClick(dispatchAction("approve"))}
              >
                Approve
              </button>
            </>
          )}
        </>
      )}
    >
      {data?.installationServiceOrder?._id && (
        <>
          {approveDisabled && (
            <Disclosure defaultOpen>
              <Disclosure.Panel
                as="div"
                className="flex items-center gap-x-6 bg-yellow-500 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
              >
                {({ close }) => (
                  <>
                    <p className="text-sm leading-6 text-white">
                      <strong className="font-semibold">
                        This Service Order Requires Resolution With AMC Mobile
                      </strong>
                    </p>
                    <div className="flex flex-1 justify-end">
                      <button
                        onClick={wrapClick(close)}
                        type="button"
                        className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                      >
                        <span className="sr-only">Dismiss</span>
                        <XMarkIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </>
                )}
              </Disclosure.Panel>
            </Disclosure>
          )}
          {[
            "AssignedToInstaller",
            "InProgress",
            "Resolved",
            "Disapproved",
            "Completed",
          ].includes(data?.installationServiceOrder?.status) && (
            <div className="block">
              <div className="border-b border-gray-200 bg-white px-6">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {orderTabs.map((_orderTab) => (
                    <button
                      key={_orderTab.name}
                      onClick={wrapClick(__setOrderTab(_orderTab.href))}
                      disabled={
                        !_orderTab.activeStatues.includes(
                          data?.installationServiceOrder?.status
                        )
                      }
                      className={classNames(
                        orderTab === _orderTab.href
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                        "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                      aria-current={
                        orderTab === _orderTab.href ? "page" : undefined
                      }
                    >
                      {_orderTab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          )}
          <div className="flex-1 w-full min-h-[65vh] max-h-[65vh] overflow-y-auto  sm:p-6">
            {orderTab === "OrderDetails" && (
              <RegularizationInstallationServiceOrderView
                installationServiceOrder={data?.installationServiceOrder}
              />
            )}
            {orderTab === "OrderHistory" && (
              <ServiceOrderHistoryView
                history={data?.installationServiceOrder?.history}
              />
            )}
            {orderTab === "ResolutionDetails" && (
              <ReplacementServiceOrderResolutionView
                resolution={{
                  ...{
                    ...data?.installationServiceOrder?.resolution,
                    oldMeterNumber:
                      data?.installationServiceOrder?.request?.meterCode,
                  },
                  installedMeter:
                    data?.installationServiceOrder?.installedMeter,
                }}
                result={data?.installationServiceOrder?.result}
                recoveryData={
                  data?.installationServiceOrder?.resolution?.recoveryData ||
                  data?.installationServiceOrder?.request?.inspection
                    ?.resolution?.recoveryData
                }
                oldMeterNumber={""}
                type="Regularization"
              />
            )}
            {orderTab === "PrepaymentDetails" && (
              <InstallationServiceOrderPrepaymentView
                serviceOrder={data?.installationServiceOrder}
              />
            )}
          </div>
        </>
      )}
    </Modal>
  );
}
