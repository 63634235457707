import { FormikProps, useFormik } from "formik";
import lodash from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { classNames, wrapClick } from "utils";
import { read, utils } from "xlsx";

export interface WorkOrderAssignmentsViewProps {
  assignments: {
    sn: string;
    cmsRegionCode: string;
    districtCode: string;
    serviceOrderCode: string;
    serviceOrderType: string;
    servicePointCode: string;
    customerName: string;
    customerPhone: string;
    emailAddress: string;
    geoCode: string;
    tariffClassCode: string;
    meterPhase: string;
    ghanaPostAddress: string;
    batchId: string;
    orgId: string;
    cosemLogicalDeviceName: string;
    mfgSerialNumber: string;
    customerSerialNumber: string;
    manufacturer: string;
    modelType: string;
    ipAddress: string;
    gprsModuleSerialNumber: string;
    firmwareType: string;
    firmwareVersion: string;
    llsSecret: string;
    hlsSecret: string;
    authentication: string;
    encryptionKey: string;
    macAddress: string;
    badgeId: string;
  }[];
  type: "Failed" | "Successful";
}

const headers = lodash.toPairs({
  sn: "SN",
  cmsRegionCode: "CMS REGION CODE",
  districtCode: "DISTRICT CODE",
  serviceOrderCode: "SERVICE ORDER CODE",
  serviceOrderType: "SERVICE ORDER TYPE",
  servicePointCode: "SERVICE POINT NUMBER",
  customerName: "CUSTOMER NAME",
  customerPhone: "CUSTOMER PHONE",
  emailAddress: "EMAIL",
  geoCode: "GEOCODE",
  tariffClassCode: "TARIFF CODE",
  meterPhase: "METER PHASE",
  ghanaPostAddress: "DIGITAL ADDRESS",
  farmingOutContractor: "CONTRACTOR CODE",
  farmingOutAccount: "AGENT CODE",
  presetAmount: "PRESET AMOUNT",
  batchId: "Batch ID",
  orgId: "Org ID",
  cosemLogicalDeviceName: "COSEM Logical Device Name",
  mfgSerialNumber: "MFG Serial Number",
  customerSerialNumber: "Customer Serial Number",
  manufacturer: "Manufacturer",
  modelType: "Model Type",
  ipAddress: "IP Address",
  gprsModuleSerialNumber: "GPRS Module Serial Number",
  firmwareType: "Firmware Type",
  firmwareVersion: "Firmware Version",
  llsSecret: "LLS Secret",
  hlsSecret: "HLS Secret",
  authentication: "Authentication",
  encryptionKey: "Encryption Key",
  macAddress: "MAC",
  badgeId: "Badge ID",
  statusReason: "Status Reason",
});

const WorkOrderAssignmentsView: FC<WorkOrderAssignmentsViewProps> = ({
  assignments,
  type,
}) => {
  if (assignments.length) {
    return (
      <table className="min-w-full flex-1 divide-y divide-gray-200 overflow-x-scroll">
        <thead className="bg-gray-50 sticky top-0 z-10">
          <tr>
            {headers?.map((column: any, idx: number) => (
              <th
                scope="col"
                key={idx}
                className={classNames(
                  idx === 0 ? "sticky left-0 bg-gray-50" : "",
                  "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                )}
              >
                {column?.[1]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {assignments?.map((assignment, key) => {
            return (
              <tr key={key}>
                {headers?.map((column, idx) => {
                  return (
                    <td
                      key={idx}
                      className={classNames(
                        "px-6 py-4 whitespace-nowrap text-sm ",
                        "text-gray-500"
                      )}
                    >
                      {(assignment as any)[column?.[0]] || "N/A"}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  return (
    <div className="flex-1 flex w-full p-6">
      <div className="relative flex flex-col w-full rounded-lg border-2 border-dashed border-gray-300 p-6 items-center justify-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
        {/* <UserPlusIcon
              className="mx-auto h-10 w-10 text-gray-400"
              stroke="currentColor"
              strokeWidth={1}
              aria-hidden="true"
            /> */}
        <h3 className="mt-2 text-md font-medium text-gray-900">
          No assignment here
        </h3>
        <p className="mt-1 text-md text-gray-500">
          {type} assignments would appear here
        </p>
      </div>
    </div>
  );
};

export default WorkOrderAssignmentsView;
