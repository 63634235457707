import { FC } from "react";
import { IMeterShipmentFormSchema } from "./schema";
import numeral from "numeral";

const MeterInfoSummary: FC<{ data: IMeterShipmentFormSchema["shipmentInfo"], values: IMeterShipmentFormSchema }> = ({ data, values }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className=''>
      <span className="text-xs font-light">Shipment Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div className="col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Shipment Code
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.code || "N/A"}
          </div>
        </div>
        <div className="col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Shipment Description
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.description || "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className='pt-6'>
      <span className="text-xs font-light">Meters Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>

        <div>
          <span className="block text-sm font-light text-gray-700">
            Brand
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.brand?.name || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Model
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.model?.name || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Quantity
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {numeral(values?.metersInfo?.meterSystemDetails?.length).format("0,0") || "N/A"} Meters
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MeterInfoSummary;