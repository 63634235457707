import { FC } from "react";

import { Header } from "components";
import { DistrictPicker } from "containers";
import { useReactiveVar } from "@apollo/client";
import { currentDistrictVar, setCurrentDistrict } from "apollo/cache/auth";

interface OfficeHeaderProps {
  renderActions?: FC<{}>;
}

const OfficeHeader: FC<OfficeHeaderProps> = ({ renderActions }) => {
  const district = useReactiveVar(currentDistrictVar);

  return (
    <Header
      renderActions={() => (
        <div className="flex items-center space-x-3">
          <DistrictPicker
            id="district"
            labelHidden={true}
            setFieldValue={(_field: string, value: string) =>
              setCurrentDistrict(value)
            }
            lockFirstItem={true}
            values={{ district }}
            rawId={true}
          />
          {renderActions && (
            <>
              <div className="h-6 w-px bg-gray-300" />
              {renderActions?.({})}
            </>
          )}
        </div>
      )}
    />
  );
};

export default OfficeHeader;
