import { currentUserVar, UserRole } from "apollo/cache/auth";

export default function withRoles<T = any>(roles: UserRole[]) {
  const currentUser = currentUserVar();
  return (val: T, alt?: T): T | null => {
    if (roles.length > 0) {
      if (roles.includes(currentUser?.role)) {
        return val;
      }
      return alt ?? null;
    }
    return val;
  };
}