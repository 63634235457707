import { useState } from "react";

interface DownloadFileProps {
  readonly onStart?: () => void;
  readonly onEnd?: () => void;
  readonly onError: () => void;
  readonly getFileName: () => string;
}

interface DownloadedFileInfo {
  readonly downloadAction: (path: string) => Promise<void>;
  readonly downloadLoading: boolean;
}

const useDownloadFile = ({
  onStart,
  onEnd,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const downloadAction = async (path: string) => {
    try {
      onStart?.();
      setDownloadLoading(true);
      const link = document.createElement("a");
      link.href = path;
      link.setAttribute("download", getFileName());
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      onEnd?.();
      setDownloadLoading(false);
    } catch (error) {
      setDownloadLoading(false);
      onError();
    }
  };

  return { downloadAction, downloadLoading };
};

export default useDownloadFile;


export const getExportDateField = (status: string) => {

  switch(status) {
    case "AssignedToSupplier":
      return "assignedAt";
    case "Rejected":
      return "rejectedAt";
    case "AssignedToInstaller":
      return "contractorAssignedAt";
    case "InProgress":
      return "startedAt";
    case "Resolved":
      return "resolvedAt";
    case "Disapproved":
      return "disapprovedAt";
    case "Completed":
      return "completedAt";
    default:
      return "assignedAt"
  };
}