import { FC } from "react";
import { withRoles, wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Modal, ContractorUserView } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import toast from "react-hot-toast";
import { GET_CONTRACTOR_USER } from "./view";

const ENABLE_CONTRACTOR_USER = gql`
  mutation EnableContractorUser($id: ID!) {
    enableContractorUser(id: $id) {
      _id
    }
  }
`;

interface EnableContractorUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const EnableContractorUserContainer: FC<EnableContractorUserContainerProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_CONTRACTOR_USER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const [enableContractorUser, { loading: loadingEnable }] = useMutation(
    ENABLE_CONTRACTOR_USER
  );

  const deleteItem = async () => {
    await enableContractorUser({
      variables: {
        id: searchParams.id,
      },
    }).then(({ data }) => {
      if (data?.enableContractorUser?._id) {
        toast(
          JSON.stringify({
            type: "success",
            title: "User Enabled Successfully",
          })
        );
        refetch?.();
        setOpen(false);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not enable User" })
        );
      }
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title='Enable Agent'
      description='Are you sure you want to enable this agent?  '
      renderActions={() => (
        <>
          {withRoles(["Super"])(
            <button
              type='button'
              disabled={loadingEnable}
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(deleteItem)}
            >
              {loadingEnable ? "Enabling Agent..." : "Enable Agent"}
            </button>
          )}
        </>
      )}
    >
      {data?.user?._id && <ContractorUserView user={data?.user} />}
    </Modal>
  );
};

export default EnableContractorUserContainer;
