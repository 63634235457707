import lodash from "lodash";
import { FC } from "react";
import { classNames } from "utils";

export interface MeterShipmentShipmentsViewProps {
  shipments: {
    sn: string;
    status: string;
    statusReason: string;
    meterCode: string;
    meterSystemDetails: any;
  }[];
  status: "Failed" | "Successful";
  type: "Internal" | "External";
}

const MeterShipmentShipmentsView: FC<MeterShipmentShipmentsViewProps> = ({
  shipments,
  status,
  type,
}) => {
  const headers = lodash.toPairs({
    ...(type === "Internal"
      ? { meterCode: "Meter NUMBER" }
      : {
        presetAmount: "Preset Amount",
        batchId: "Batch ID",
        orgId: "Org ID",
        cosemLogicalDeviceName: "COSEM Logical Device Name",
        mfgSerialNumber: "MFG Serial Number",
        customerSerialNumber: "Customer Serial Number",
        manufacturer: "Manufacturer",
        modelType: "Model Type",
        ipAddress: "IP Address",
        gprsModuleSerialNumber: "GPRS Module Serial Number",
        firmwareType: "Firmware Type",
        firmwareVersion: "Firmware Version",
        llsSecret: "LLS Secret",
        hlsSecret: "HLS Secret",
        authentication: "Authentication",
        encryptionKey: "Encryption Key",
        macAddress: "MAC",
        badgeId: "Badge ID",
      }),
    ...(status === "Failed" ? { statusReason: "Status Reason" } : {}),
  });

  if (shipments.length) {
    return (
      <table className="min-w-full flex-1 divide-y divide-gray-200 overflow-x-scroll">
        <thead className="bg-gray-50 sticky top-0 z-10">
          <tr>
            <th
              scope="col"
              className={classNames(
                "sticky left-0 bg-gray-50",
                "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
              )}
            >
              #
            </th>
            {headers?.map((column: any, idx: number) => (
              <th
                scope="col"
                key={idx}
                className={classNames(
                  "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                )}
              >
                {column?.[1]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {shipments?.map((shipment, key) => {
            const datum = {
              ...shipment,
              ...shipment?.meterSystemDetails
            }
            return (
              <tr key={key}>
                <td
                  className={classNames(
                    "px-6 py-4 whitespace-nowrap text-sm sticky left-0 bg-gray-50",
                    "text-gray-500"
                  )}
                >
                  {key + 1}
                </td>
                {headers?.map((column, idx) => {
                  return (
                    <td
                      key={idx}
                      className={classNames(
                        "px-6 py-4 whitespace-nowrap text-sm ",
                        "text-gray-500"
                      )}
                    >
                      {datum?.[column?.[0]] || "N/A"}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  return (
    <div className="flex-1 flex w-full p-6">
      <div className="relative flex flex-col w-full rounded-lg border-2 border-dashed border-gray-300 p-6 items-center justify-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
        {/* <UserPlusIcon
              className="mx-auto h-10 w-10 text-gray-400"
              stroke="currentColor"
              strokeWidth={1}
              aria-hidden="true"
            /> */}
        <h3 className="mt-2 text-md font-medium text-gray-900">
          No shipment here
        </h3>
        <p className="mt-1 text-md text-gray-500">
          {status} shipments would appear here
        </p>
      </div>
    </div>
  );
};

export default MeterShipmentShipmentsView;
