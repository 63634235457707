import moment from "moment";
import { FC } from "react";
import { wrapImage } from "utils";

const ServiceInfoSummary: FC<{
  data: any;
}> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className="grid grid-cols-3 gap-4">
      <div>
        <span className="block text-sm font-light text-gray-700">
          Meter Number
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.retiredMeter?.code || "N/A"}
        </div>
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
          Meter Type
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.retiredMeter?.modelType || "N/A"}
        </div>
      </div>
      <div className="row-span-4 col-span-1">
        <span className="block text-sm font-light text-gray-700">
          Meter Images
        </span>
        <div className="mt-2 grid grid-cols-1 gap-3">
          {data?.resolution?.previousReading?.readingImageUrl ? (
            wrapImage(
              <img
                src={data?.resolution?.previousReading?.readingImageUrl}
                alt={"back"}
                className="w-full h-48 text-xs object-cover object-center rounded"
              />
            )
          ) : (
            <div className="h-48 flex items-center justify-center text-xs text-gray-600">
              <span>No Image Provided</span>
            </div>
          )}
        </div>
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
          Meter Brand
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.retiredMeter?.modelMeta?.brandName || "N/A"}
        </div>
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
          Meter Model
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.retiredMeter?.modelMeta?.modelName || "N/A"}
        </div>
      </div>
      <div className="">
        <span className="block text-sm font-light text-gray-700">
          Meter Condition
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.resolution?.recoveryData?.meterCondition || "N/A"}
        </div>
      </div>
      <div className="">
        <span className="block text-sm font-light text-gray-700">
          Meter Removal Date
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {moment(
            data?.resolution?.previousReading?.readingDate ??
              data?.resolution?.finalReading?.readingDate ??
              data?.resolvedAt ??
              data?.completedAt ??
              data?.reverseSyncedAt
          ).format("DD/MM/YYYY HH:mm A") || "N/A"}{" "}
        </div>
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
          Contractor
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.farmingOutContractor?.name || "N/A"}
        </div>
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
          Contractor Agent
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {[
            (data?.farmingOutContractorUser?.lastName || "")?.trim(),
            (data?.farmingOutContractorUser?.firstName || "")?.trim(),
          ]
            .join(" ")
            .trim() || "N A"}{" "}
          ({data?.farmingOutContractorUser?.code || "N A"})
        </div>
      </div>
    </div>
  </div>
);

export default ServiceInfoSummary;
