import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";

interface MaterRetirementSetViewProps {
  meterRetirementSet: {
    status: string;
    meta: any;
    _id: string;
    code: string;
    description: string;
    district: {
      _id: string;
      code: string;
      name: string;
    }
    createdBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    createdAt: string;
    updatedAt: string;
  };
}

const MaterRetirementSetView: FC<MaterRetirementSetViewProps> = ({ meterRetirementSet }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Retirement Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterRetirementSet?.code || "N/A"}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Description
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterRetirementSet?.description || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                District
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterRetirementSet?.district?.name || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Created At
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {moment(meterRetirementSet?.createdAt).format(
                  dateFormat + ", hh:mm A"
                )}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Created By
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {[
                  (meterRetirementSet?.createdBy?.lastName || "")?.trim(),
                  (meterRetirementSet?.createdBy?.firstName || "")?.trim(),
                ]
                  .join(" ")
                  .trim() || "N A"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Upload Statistics Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Status
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterRetirementSet?.status || "N/A"}
              </div>
            </div>
            <div className="col-start-1">
              <span className="block text-sm font-light text-gray-700">
                Total Orders
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {numeral(meterRetirementSet?.meta?.totalRetirements).format("0,0") ||
                  "N/A"}{" "}
                Orders
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Total Successful
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {numeral(meterRetirementSet?.meta?.totalSuccessRetirements).format(
                  "0,0"
                ) || "N/A"}{" "}
                Orders
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Total Failed
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {numeral(meterRetirementSet?.meta?.totalFailedRetirements).format(
                  "0,0"
                ) || "N/A"}{" "}
                Orders
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterRetirementSetView;
