import MetersInfoForm from "./meters-info-form";
import MetersInfoSummary from "./meters-info-summary";
import ShipmentInfoForm from "./shipment-info-form";
import ShipmentInfoSummary from "./shipment-info-summary";

const formSteps = [
  {
    name: "Shipment Info",
    description: "Information about shipment",
    accessor: "shipmentInfo",
    FormComponent: ShipmentInfoForm,
    SummaryComponent: ShipmentInfoSummary,
  },
  {
    name: "Meters Info",
    description: "Information about meters",
    accessor: "metersInfo",
    FormComponent: MetersInfoForm,
    SummaryComponent: MetersInfoSummary,
  },
];

export default formSteps;
