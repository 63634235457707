import { useReactiveVar } from "@apollo/client";
import { FC, useEffect, useMemo } from "react";
import { SearchSelectInput } from "components/core";
import { currentUserVar } from "apollo/cache/auth";
import lodash from "lodash";

interface DistrictPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
  position?: "top" | "bottom";
  lockFirstItem?: boolean;
}

const DistrictPickerContainer: FC<DistrictPickerContainerProps> = ({
  id,
  label,
  rawId,
  lockFirstItem,
  ...form
}) => {
  const currentUser = useReactiveVar(currentUserVar);
  const districts = useMemo(
    () => lodash.sortBy(currentUser?.contractor?.districts ?? [], "name"),
    [currentUser]
  );

  useEffect(() => {
    if (lockFirstItem && districts) {
      if (!lodash.get(form.values, id ?? "district")) {
        form.setFieldValue(
          id ?? "district",
          rawId ? lodash.first(districts)?._id : lodash.first(districts)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districts, id, lockFirstItem, rawId]);

  return (
    <SearchSelectInput
      id={id ?? "district"}
      label={label ?? "District"}
      placeholder="Select District"
      options={districts?.map((district: any) => ({
        label: {
          title: district.name as string,
        },
        value: rawId ? district._id : district,
      }))}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default DistrictPickerContainer;
