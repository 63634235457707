import { FC } from "react";
import { Link, useSearch } from "react-location";
// import { LocationGenerics } from "router/location";
import {
  BuildingOfficeIcon,
  SquaresPlusIcon,
  ClockIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";
import { classNames, useTableData, useUrlState } from "utils";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import lodash from "lodash";
import { currentDistrictVar, currentUserVar } from "apollo/cache/auth";
import { Avatar, CalendarHeader, Header, OfficeHeader, Shimmers } from "components";
import numeral from "numeral";
import TableBodyComponent from "components/tables/table-body";
import { LocationGenerics } from "router/location";
import { ContractorPicker } from "containers";

const stats = [
  {
    id: 1,
    name: "Installations",
    accessor: "installations",
    icon: SquaresPlusIcon,
  },
  {
    id: 2,
    name: "Replacements",
    accessor: "replacements",
    icon: ({ className }: any) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        className={className}
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
        />
        <path
          d="M20 16H13.5L15.5 14"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5 18H20L18 20"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    id: 4,
    name: "Backlogs",
    accessor: "backlogInstallations",
    icon: ClockIcon,
  },
  {
    id: 3,
    name: "Regularizations",
    accessor: "regularizationInstallations",
    icon: ({ className }: any) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        className={className}
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M18 20l2-2m-2 2-2-2m-12 3 M15.5 14l2 2m-2-2-2 2"
        />
      </svg>
    ),
  },
];

const SERVICE_ORDER_SUMMARY = gql`
  query GetServiceOrdersSummary($district: ID, $meterContractor: ID) {
    installations: getInstallationServiceOrderSummary(
      district: $district
      meterContractor: $meterContractor
    ) {
      AssignedToSupplier
      AssignedToInstaller
      Resolved
      InProgress
      Completed
      Disapproved
    }
    replacements: getReplacementServiceOrderSummary(
      district: $district
      meterContractor: $meterContractor
    ) {
      AssignedToSupplier
      AssignedToInstaller
      Resolved
      InProgress
      Completed
      Rejected
      Disapproved
    }
    backlogInstallations: getBacklogInstallationServiceOrderSummary(
      district: $district
      meterContractor: $meterContractor
    ) {
      AssignedToSupplier
      AssignedToInstaller
      Resolved
      InProgress
      Completed
      Disapproved
    }
    regularizationInstallations: getRegularizationInstallationServiceOrderSummary(
      district: $district
      meterContractor: $meterContractor
    ) {
      AssignedToSupplier
      AssignedToInstaller
      Resolved
      InProgress
      Completed
      Disapproved
    }
  }
`;

const CONTRACTOR_SERVICE_ORDER_SUMMARY = gql`
  query GetContractorsWithSummary(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $parentContractor: ID
  ) {
    rows: getContractors(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      district: $district
      parentContractor: $parentContractor
    ) {
      _id
      code
      name
      summary {
        _id
        installations {
          AssignedToInstaller
          Resolved
          InProgress
          Completed
          Disapproved
        }
        replacements {
          AssignedToInstaller
          Resolved
          InProgress
          Completed
          Disapproved
        }
        backlogInstallations {
          AssignedToInstaller
          Resolved
          InProgress
          Completed
          Disapproved
        }
        regularizationInstallations {
          AssignedToInstaller
          Resolved
          InProgress
          Completed
          Disapproved
        }
      }
    }
  }
`;
const CONTRACTOR_USER_SERVICE_ORDER_SUMMARY = gql`
  query GetContractorUsersWithSummary(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $contractor: ID
  ) {
    rows: getContractorUsers(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      contractor: $contractor
    ) {
      _id
      code
      username
      lastName
      firstName
      summary {
        _id
        installations {
          AssignedToInstaller
          Resolved
          InProgress
          Completed
          Disapproved
        }
        replacements {
          AssignedToInstaller
          Resolved
          InProgress
          Completed
          Disapproved
        }
        backlogInstallations {
          AssignedToInstaller
          Resolved
          InProgress
          Completed
          Disapproved
        }
        regularizationInstallations {
          AssignedToInstaller
          Resolved
          InProgress
          Completed
          Disapproved
        }
      }
    }
    count: getContractorUsersCount(
      search: $search
      searchFields: $searchFields
      contractor: $contractor
    )
  }
`;

const DashboardPage: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const currentDistrict = useReactiveVar(currentDistrictVar);
  const [contractor, setContractor] = useUrlState("contractor");
  const { data } = useQuery(SERVICE_ORDER_SUMMARY, {
    variables: {
      ...(currentDistrict ? { district: currentDistrict } : {}),
      meterContractor: currentUser?.contractor?._id,
      sort: "name"
    },
  });

  const {
    data: dataContractors,
    loading: loadingContractors,
    networkStatus: networkStatusContractors,
  } = useQuery(contractor ? CONTRACTOR_USER_SERVICE_ORDER_SUMMARY : CONTRACTOR_SERVICE_ORDER_SUMMARY, {
    variables: {
      ...(currentDistrict ? { district: currentDistrict } : {}),
      contractor,
      page: 0,
      pageSize: 0
    },
  });

  const records = useTableData(dataContractors || {});

  return (
    <main className="flex-1 flex flex-col overflow-hidden  bg-gray-50">
      <OfficeHeader />
      {/* {JSON.stringify(currentUser, null, 2)} */}
      <div className=" flex-1 overflow-y-auto p-4 sm:p-6 overflow-hidden flex flex-col gap-8">
        <dl className="grid gap-5 grid-cols-3">
          <div className="relative isolate overflow-hidden rounded-lg bg-primary-400 px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6 row-span-1 col-span-1">
            <div className="absolute inset-y-0 right-8 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-15deg] bg-primary-500 shadow-xl shadow-primary-400 ring-1 ring-primary-400 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
            <div className="absolute inset-y-0 right-24 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-20deg] bg-primary-600 shadow-xl shadow-primary-500 ring-1 ring-primary-500 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
            <div className="absolute inset-0 -z-10 bg-gradient-to-b from-transparent via-primary-600/80 to-primary-600" />
            <div className="">
              <div className="flex items-center">
                <Avatar
                  alt={[
                    currentUser?.firstName || "U",
                    currentUser?.lastName || "A",
                  ].join(" ")}
                  size="md"
                  src={currentUser?.profileImageUrl}
                />
                <div>
                  <div className="flex items-center">
                    <h1 className="ml-3 text-white text-xl font-extralight leading-7  sm:truncate sm:leading-9">
                      Welcome back{" "}
                      <span className="font-semibold">
                        {currentUser?.firstName}
                      </span>
                    </h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Company</dt>
                    <dd className="flex items-center text-sm font-medium capitalize text-gray-50 sm:mr-6">
                      <BuildingOfficeIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-100"
                        aria-hidden="true"
                      />
                      {currentUser?.contractor?.name}
                    </dd>
                    <dt className="sr-only">Account status</dt>
                    <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-50 sm:mr-6 sm:mt-0">
                      <IdentificationIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                        aria-hidden="true"
                      />
                      {currentUser?.role}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          {stats.map((item) => (
            <div
              key={item.id}
              className=" overflow-hidden rounded-lg shadow col-span-1 flex flex-col"
            >
              <div className="overflow-hidden rounded-t-lg bg-white p-4 sm:p-6 col-span-1 flex items-center space-x-3">
                <div className="rounded-md bg-primary-500 p-3">
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <dt className="flex-1">
                  <p className="truncate text-sm font-medium text-gray-500">
                    {item.name}
                  </p>
                  <p className="text-2xl font-semibold text-gray-900">
                    {numeral(
                      lodash
                        .chain(data?.[item.accessor])
                        ?.omit(["__typename"])
                        ?.toPairs()
                        ?.map(([key, value]) => value)
                        ?.sum()
                        ?.value()
                    ).format("0,0")}
                  </p>
                </dt>
                <dd className="flex flex-col space-y-1 items-end">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      data?.[item.accessor]?.AssignedToSupplier
                        ? "bg-primary-100 text-primary-800"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    {numeral(
                      data?.[item.accessor]?.AssignedToSupplier ?? 0
                    ).format("0,0")}{" "}
                    Pending
                  </span>
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      data?.[item.accessor]?.Resolved
                        ? "bg-primary-100 text-primary-800"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    {numeral(data?.[item.accessor]?.Resolved ?? 0).format(
                      "0,0"
                    )}{" "}
                    Resolved
                  </span>
                </dd>
              </div>
              <div className="rounded-b-lg bottom-0 bg-gray-50 px-4 py-4 sm:px-6 ">
                <div className="text-sm">
                  <Link
                    to={`/${lodash.kebabCase(item.accessor)}s`}
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    {" "}
                    View all
                    <span className="sr-only"> {item.name} stats</span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </dl>
        <div className="grid gap-5 grid-cols-3">
          <div className="col-span-3">
            <TableBodyComponent
              title={`Orders Per ${
                contractor ? "Contractor Agent" : "Contractor"
              }`}
              exportFileName={`Orders Per ${
                contractor ? "Contractor Agent" : "Contractor"
              }`}
              exportTypes={["EXCEL"]}
              noSpace={true}
              loading={
                loadingContractors && ![4, 6].includes(networkStatusContractors)
              }
              data={{
                ...records,
              }}
              renderHeaderItems={() => (
                <div className="sm:flex space-x-2">
                  <ContractorPicker
                    id="contractor"
                    labelHidden={true}
                    label={""}
                    placeholder="Select Contractor"
                    setFieldValue={(_: any, value: string) =>
                      setContractor(value)
                    }
                    filter={{ district: currentDistrict || undefined }}
                    values={{ contractor }}
                    rawId={true}
                  />
                </div>
              )}
              renderColumns={() => (
                <>
                  {/* <tr className="hidden print:table-row">
                    <th
                      scope="col"
                      colSpan={2}
                      className="sticky top-0 border-t border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-center text-md font-bold text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Order Per
                    </th>
                  </tr> */}
                  <tr>
                    <th
                      scope="col"
                      rowSpan={2}
                      className="sticky bg-opacity-75 backdrop-blur backdrop-filter left-0 border-y  border-r border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      {contractor ? "Contractor Agent" : "Contractor"}
                    </th>
                    {stats?.map((stat) => (
                      <th
                        id={stat.accessor}
                        scope="col"
                        colSpan={5}
                        className="border-t border-r last:border-r-0 border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-center text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        {stat.name}
                      </th>
                    ))}
                  </tr>
                  <tr>
                    {stats?.map((stat) => (
                      <>
                        <th
                          scope="col"
                          className="border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          Assigned
                        </th>
                        <th
                          scope="col"
                          className="border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          InProgress
                        </th>
                        <th
                          scope="col"
                          className="border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          Resolved
                        </th>
                        <th
                          scope="col"
                          className="border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          Disapproved
                        </th>
                        <th
                          scope="col"
                          className="border-y border-r last:border-r-0 border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          Completed
                        </th>
                      </>
                    ))}
                  </tr>
                </>
              )}
              renderLoader={() => (
                <tr className="border-t border-gray-200 print:border-gray-700">
                  <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                    <Shimmers.SingleShimmer />
                  </td>
                  {stats?.map((stat) => (
                    <>
                  <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className="px-4 py-4 border-b border-r  last:border-r-0 border-gray-200 print:border-gray-700">
                    <Shimmers.SingleShimmer />
                  </td>
                  </>
                  ))}
                </tr>
              )}
              renderItem={(item) => (
                <tr
                  key={item._id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                >
                  <td className="sticky bg-opacity-75 backdrop-blur backdrop-filter left-0 whitespace-nowrap border-r px-4 py-2 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                    {contractor ?`${item.firstName} ${item.lastName} (${item.code})` :  item?.name}
                  </td>
                  {stats?.map((stat) => (
                    <>
                      <td className="whitespace-nowrap px-4 py-2 text-sm text-right text-gray-500 border-b border-gray-200 print:border-gray-700">
                        {numeral(
                          lodash.get(
                            item?.summary,
                            `${stat.accessor}.AssignedToInstaller`,
                            0
                          )
                        ).format("0,0") || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-sm text-right text-gray-500 border-b border-gray-200 print:border-gray-700">
                        {numeral(
                          lodash.get(
                            item?.summary,
                            `${stat.accessor}.InProgress`,
                            0
                          )
                        ).format("0,0") || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-sm text-right text-gray-500 border-b border-gray-200 print:border-gray-700">
                        {numeral(
                          lodash.get(
                            item?.summary,
                            `${stat.accessor}.Resolved`,
                            0
                          )
                        ).format("0,0") || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-sm text-right text-gray-500 border-b border-gray-200 print:border-gray-700">
                        {numeral(
                          lodash.get(
                            item?.summary,
                            `${stat.accessor}.Disapproved`,
                            0
                          )
                        ).format("0,0") || "N/A"}
                      </td>
                      <td className="whitespace-nowrap  border-r last:border-r-0 px-4 py-2 text-sm text-right text-gray-500 border-b border-gray-200 print:border-gray-700">
                        {numeral(
                          lodash.get(
                            item?.summary,
                            `${stat.accessor}.Completed`,
                            0
                          )
                        ).format("0,0") || "N/A"}
                      </td>
                    </>
                  ))}
                </tr>
              )}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashboardPage;
