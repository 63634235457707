import { gql } from "@apollo/client";

export const GET_SERVICE_ORDERS = gql`
query GetCompletedReplacementServiceOrders(
  $fromDate: Date
  $toDate: Date
  $dateField: String
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $district: ID
  $status: ReplacementServiceOrderStatus
  $priority: ReplacementServiceOrderPriority
  $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
  $farmingOutContractor: ID
  $farmingOutContractorUser: ID
  $meterRetirementSet: ID
) {
  rows: getReplacementServiceOrders(
    fromDate: $fromDate
    toDate: $toDate
    dateField: $dateField
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    district: $district
    status: $status
    priority: $priority
    installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
    farmingOutContractor: $farmingOutContractor
    farmingOutContractorUser: $farmingOutContractorUser
    meterRetirementSet: $meterRetirementSet
  ) {
    _id
    code
    farmingOutContractor {
      _id
      code
      name
    }
    farmingOutContractorUser {
      _id
      code
      username
      lastName
      firstName
    }
    meter {
      _id
      code
      modelMeta {
        brandCode
        brandName
        systemCode
        systemName
        modelCode
        modelName
        phase
      }
      modelType
    }
    retiredMeter {
      _id
      code
      modelMeta {
        brandCode
        brandName
        systemCode
        systemName
        modelCode
        modelName
        phase
      }
      modelType
    }
    resolution {
      previousReading {
        readingDate
        readingImageUrl
      }
      finalReading {
        readingDate
        readingImageUrl
      }
      recoveryData {
        meterCondition
      }
    }
    resolvedAt
    completedAt
    reverseSyncedAt
    createdAt
    updatedAt
  }
  count: getReplacementServiceOrdersCount(
    fromDate: $fromDate
    toDate: $toDate
    dateField: $dateField
    search: $search
    searchFields: $searchFields
    district: $district
    status: $status
    priority: $priority
    installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
    farmingOutContractor: $farmingOutContractor
    farmingOutContractorUser: $farmingOutContractorUser
    meterRetirementSet: $meterRetirementSet
  )
}
`;