import { useLazyQuery, gql, useReactiveVar } from "@apollo/client";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { Avatar, TextInput } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import { classNames, wrapClick } from "utils";
import { ContractorInfoFormSchema } from "./schema";
import { currentDistrictVar } from "apollo/cache/auth";

interface ContractorInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}

const GET_CONTRACTORS = gql`
  query GetContractors(
    $search: String
    $district:ID
    $searchFields: [String!]
    $sort: String
    $type: ContractorType
    $page: Int
    $pageSize: Int
  ) {
    contractors: getContractors(
      search: $search
      district:$district
      searchFields: $searchFields
      sort: $sort
      type: $type
      page: $page
      pageSize: $pageSize
    ) {
      _id
      code
      name
      ghanaPostAddress
      contactPerson {
        emailAddress
        fullName
        phoneNumber
      }
    }
  }
`;

function ContractorOwner({
  owner,
  onClick,
  isActive,
}: {
  owner: any;
  onClick: any;
  isActive: boolean;
}) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        isActive
          ? "border-primary-500 dark:border-primary-600 bg-white dark:bg-primary-800 hover:border-primary-700 dark:hover:border-primary-500"
          : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar alt={owner?.name || "N A"} src={owner?.profileImageUrl || ""} />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {owner?.name || "N A"}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {owner?.contactPerson?.phoneNumber || "N/A"}
        </p>
      </div>
    </button>
  );
}

const ContractorInfoForm: FC<ContractorInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const currentDistrict = useReactiveVar(currentDistrictVar);
  const form = useFormik({
    initialValues,
    validationSchema: ContractorInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });
  const [
    getContractors,
    { loading: contractorsLoading, data: contractorsData },
  ] = useLazyQuery(GET_CONTRACTORS, {
    // fetchPolicy: "cache-and-network",
  });

  const contractorSearchForm = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values) => {
      getContractors({
        variables: {
          type: "FarmingOutContractor",
          search: values.name,
          searchFields: ["code", "name"],
          district: currentDistrict,
          page: 0,
          pageSize: 0
        },
      });
    },
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden min-h-[50vh]">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div className="">
          <span className="text-xs font-light">
            Selected Farming Out Contractor
          </span>
          {form?.values?.contractor ? (
            <div className="border mt-2 border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
              <div className="grid grid-cols-3 gap-x-4 gap-y-6 p-4">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Contractor Code
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {form?.values?.contractor?.code || "N/A"}
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Contractor Name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {form?.values?.contractor?.name || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Contact Person Name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {form?.values?.contractor?.contactPerson?.fullName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Phone Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {form?.values?.contractor?.contactPerson?.phoneNumber ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Email Address
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {form?.values?.contractor?.contactPerson?.emailAddress ||
                      "N/A"}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex  mt-2 w-full items-center justify-center">
              <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                <UserPlusIcon
                  className="mx-auto h-10 w-10 text-gray-400"
                  stroke="currentColor"
                  strokeWidth={1}
                  aria-hidden="true"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No farming out contractor assigned
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by searching for a contractor below.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">
            Select Farming Out Contractor
          </span>
          <form
            onSubmit={contractorSearchForm.handleSubmit}
            className="grid grid-cols-6 gap-6 mt-2"
          >
            <div className="col-span-6 sm:col-span-5">
              <TextInput
                id="name"
                label="Contractor Name"
                type="text"
                placeholder="e.g. Alpha Gamma Beta"
                {...contractorSearchForm}
              />
            </div>
            <div className="col-span-6 sm:col-span-1 items-end justify-end flex">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                {contractorsLoading ? "Searching..." : "Search"}
              </button>
            </div>
          </form>
          <div className="grid grid-cols-3 gap-6 mt-6 pt-6 border-t border-gray-200">
            {contractorsData?.contractors?.map?.((user: any) => (
              <ContractorOwner
                key={user._id}
                isActive={user._id === form.values?.contractor?._id}
                onClick={wrapClick(() =>
                  form.setFieldValue("contractor", user)
                )}
                owner={user}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ContractorInfoForm;
