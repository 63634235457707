import { makeVar } from "@apollo/client";
import Cookies from "js-cookie";
import config from "config";
import lodash from "lodash";

export const UserRoles = ["Super", "Admin"] as const;
export type UserRole = (typeof UserRoles)[number];

interface IUser {
  _id: string;
  code: string;
  lastName: string;
  firstName: string;
  gender?: "Male" | "Female";
  ghanaCardNumber?: string;
  phoneNumber: string;
  emailAddress: string;
  profileImageUrl: string;
  contractor: {
    _id: string;
    code: string;
    name: string;
    districts: {
      _id: string;
      code: string;
      name: string;
    }[];
  };
  meta: {
    isPasswordSet: boolean;
    lastLoginAt: Date;
  };
  role: UserRole;
  createdAt: Date;
  updatedAt: Date;
}

export const isLoggedInVar = makeVar<boolean>(
  !!Cookies.get("subs-contractor:auth:token")
);
export const currentTokenVar = makeVar<string | null>(
  Cookies.get("subs-contractor:auth:token") || null
);
export const currentDistrictVar = makeVar<string | null>(
  Cookies.get("subs-contractor:auth:district") || ""
);
export const currentUserVar = makeVar<IUser>(
  JSON.parse(Cookies.get("subs-contractor:auth:user") ?? "{}") ?? null
);
export const currentPushTokenVar = makeVar<string | null>(
  Cookies.get("subs-contractor:auth:push-token") || null
);

export const setAuth = ({ user, token }: { user: IUser; token: string }) => {
  const district = lodash.first(
    lodash.sortBy(user.contractor.districts || [], "name")
  );
  currentUserVar(user);
  Cookies.set("subs-contractor:auth:user", JSON.stringify(user), {
    ...config.cookies,
    expires: 1
  });
  isLoggedInVar(true);
  currentTokenVar(token);
  Cookies.set("subs-contractor:auth:token", token, {
    ...config.cookies,
    expires: 1,
  });
  if (district) {
    currentDistrictVar(district._id);
    Cookies.set("subs-contractor:auth:district", district._id, {
      ...config.cookies,
      expires: 1
    });
  }
};

export const setMe = (user: IUser) => {
  currentUserVar(user);
  Cookies.set("subs-contractor:auth:user", JSON.stringify(user), {
    ...config.cookies,
    expires: 1
  });
};

export const setCurrentDistrict = (district: string) => {
  currentDistrictVar(district);
  Cookies.set("subs-contractor:auth:district", district, {
    ...config.cookies,
    expires: 1
  });
};

export const setPushToken = (token: any) => {
  currentPushTokenVar(token);
  Cookies.set("subs-contractor:auth:push-token", token, { ...config.cookies, expires: 1 });
};

export const clearAuth = () => {
  isLoggedInVar(false);
  currentTokenVar(null);
  currentDistrictVar("");
  // TODO: handle extra checks for better user experience
  Object.keys(Cookies.get()).forEach((key) => {
    Cookies.remove(key, { ...config.cookies });
  });
};
