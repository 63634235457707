import * as Yup from 'yup';

export interface IMeterRetirementSetFormSchema {
  info: {
    district: string;
    description: string;
    requestCodes: string[];
  }
};

export const InfoFormSchema = Yup.object().shape({
  district: Yup.string().notRequired(),
  description: Yup.string().required(),
  requestCodes: Yup.array().of(Yup.string()).min(1).required(),
})