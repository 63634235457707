import InfoForm from "./info-form";

const formSteps = [
  {
    name: "Retirements Info",
    description: "Information about service orders with meters to be retired",
    accessor: "info",
    FormComponent: InfoForm,
  },
]

export default formSteps;
