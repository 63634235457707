import lodash from 'lodash';
import numeral from 'numeral';
import { FC } from 'react'

interface MeterModelViewProps {
  meterModel: {
    code: string;
    name: string;
    brand: {
      _id: string;
      code: string;
      name: string;
    }
    system: {
      _id: string;
      code: string;
      name: string;
    }
    contractor: {
      _id: string;
      code: string;
      name: string;
    }
    voltage: number;
    currentRating: number;
    mechanism: string;
    communicationType: string;
    digits: number;
    phase: (1 | 3);
    type: ("Prepaid" | "Postpaid");
  };
}

const MeterModelView: FC<MeterModelViewProps> = ({ meterModel }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Meter Model Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              System
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.system?.name || "N/A"} ({meterModel?.system?.code || "N/A"})
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Brand
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.brand?.name || "N/A"} ({meterModel?.brand?.code})
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Contractor
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.contractor?.name || "N/A"}
            </div>
          </div>

          <div className=''>
            <span className="block text-sm font-light text-gray-700">
              Model Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Model Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Meter Model Specification Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.type || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Communication Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.startCase(meterModel?.communicationType) || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Phase
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.phase || "N/A"} Phase
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Mechanism
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.mechanism || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Digits
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.digits || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Voltage
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.voltage || "N/A"}V
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Current Rating
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterModel?.currentRating || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeterModelView