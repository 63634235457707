import { FC } from "react";
import numeral from "numeral";
import moment from "moment";
import { ICurrentConfig } from "apollo/cache/config";
import lodash from "lodash";
import { wrapImage } from "utils";

const MeterInfoSummary: FC<{
  data: {
    meterNumber: string;
    meterModel: string;
    meterBrand: string;
    meterImageUrl: string;
    meterCondition: string;
    meterDescription: string;
  };
  config: ICurrentConfig;
}> = ({ data, config: { dateFormat } }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className="grid grid-cols-3 gap-4">
      <div className="">
        <span className="block text-sm font-light text-gray-700">
         Meter Number
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.meterNumber || "N/A"}
        </div>
      </div>
      <div className="row-span-5 col-span-2">
        <span className="block text-sm font-light text-gray-700">
          Meter Images
        </span>
        <div className="mt-2 grid grid-cols-1 gap-3">
          {wrapImage(
            <img
              src={data?.meterImageUrl}
              alt={"back"}
              className="w-full h-48 text-xs object-cover object-center rounded"
            />
          )}
        </div>
      </div>
      <div className="">
        <span className="block text-sm font-light text-gray-700">
          Meter Brand
        </span>
        <div className="mt-1 block w-full sm:text-sm">
        {data?.meterBrand || "N/A"}
        </div>
      </div>
      <div className="">
        <span className="block text-sm font-light text-gray-700">
          Meter Model
        </span>
        <div className="mt-1 block w-full sm:text-sm">
        {data?.meterModel || "N/A"}
        </div>
      </div>
      <div className="">
        <span className="block text-sm font-light text-gray-700">
          Meter Condition
        </span>
        <div className="mt-1 block w-full sm:text-sm">
        {data?.meterCondition || "N/A"}
        </div>
      </div>
    </div>
  </div>
);

export default MeterInfoSummary;
