import { TextInput } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import toast from "react-hot-toast";

const SettingsSecurityContainer: FC = () => {


  const form = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    },
    onSubmit: (values) => {
      toast(JSON.stringify({ type: "success", title: "Settings Updated Successfully" }));
    }
  });

  return (
    <form onSubmit={form.handleSubmit} onReset={form.handleReset}>
      <div className="overflow-hidden shadow sm:rounded-md">
        <div className="bg-white px-4 py-5 sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <TextInput
                id="oldPassword"
                label='Old Passsword'
                type='password'
                placeholder='eg. **************'
                {...form}
              />
            </div>
            <div  className="hidden sm:block sm:col-span-3"/>

            <div className="col-span-6 sm:col-span-3">
              <TextInput
                id="newPassword"
                label='New Passsword'
                type='password'
                placeholder='eg. **************'
                {...form}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <TextInput
                id="repeatNewPassword"
                label='Repeat New Passsword'
                type='password'
                placeholder='eg. **************'
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 space-x-3">
          <button
            type="reset"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default SettingsSecurityContainer;