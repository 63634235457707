import lodash from "lodash";
import { FC } from "react";
import { classNames } from "utils";
import { IMeterShipmentFormSchema } from "./schema";

export interface BulkUploadInstallationServiceOrdersProps {
  data: IMeterShipmentFormSchema["metersInfo"];
  values: IMeterShipmentFormSchema;
}

const headerMap = {
  sn: "SN",
  mfgSerialNumber: "MFG Serial Number",
  customerSerialNumber: "Customer Serial Number",
  gprsModuleSerialNumber: "GPRS Module Serial Number",
};

const BulkUploadInstallationServiceOrders: FC<
  BulkUploadInstallationServiceOrdersProps
> = ({ data }) => {
  const columns = Object.keys(headerMap);

  return (
    <div className="flex-1 flex flex-col border">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 sticky top-0 z-10 border-b">
          <tr>
            {columns?.map((column: any, idx: number) => (
              <th
                scope="col"
                key={idx}
                className={classNames(
                  idx === 0 ? "sticky left-0 bg-gray-50" : "",
                  "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                )}
              >
                {lodash.get(headerMap, column)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.meterSystemDetails?.map((member, key) => {
            return (
              <tr key={key}>
                {columns?.map((column, idx) => {
                  return (
                    <td
                      key={idx}
                      className={classNames(
                        idx === 0 ? "sticky left-0 bg-gray-50" : "",
                        "px-6 py-4 whitespace-nowrap text-sm ",
                        "text-gray-500"
                      )}
                    >
                      {lodash.get(member, column, "N/A")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BulkUploadInstallationServiceOrders;
