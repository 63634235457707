import _ from "lodash";
import { FC } from "react";
import { classNames } from "utils";
import config from "config";
import { Link, useSearch } from "react-location";
import numeral from "numeral";

interface HeaderTabsProps {
  mainTabs: {name: string; href: string}[];
  altTabs?: {name: string; href: string}[];
  tabKey: string;
  summary?: Record<string, number>;
}

const HeaderTabs: FC<HeaderTabsProps> = ({ mainTabs, altTabs, tabKey, summary }) => {
const searchParams = useSearch();

  return (
    <div className="block">
      <div className="border-b border-gray-200 bg-white px-6">
        <nav className="-mb-px flex space-x-6 items-center" aria-label="Tabs">
          <Link
            search={(old) => ({
              ...old,
              [tabKey]: undefined,
              page: config.constants.page,
              pageSize: config.constants.pageSize,
            })}
            className={classNames(
              !searchParams?.[tabKey]
                ? "border-primary-500 text-primary-600"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
              "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
            )}
            aria-current={!searchParams?.[tabKey] ? "page" : undefined}
          >
            All Orders
            <span
              className={classNames(
                !searchParams?.[tabKey]
                  ? "bg-primary-100 text-primary-600"
                  : "bg-gray-100 text-gray-900",
                "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
              )}
            >
              {numeral(
                _.sum(
                  _.values(
                    _.omit(
                      summary,
                      "__typename"
                    )
                  )
                )
              ).format("0,0")}
            </span>
          </Link>
          {mainTabs.map((_orderStatus) => (
            <Link
              key={_orderStatus.name}
              search={(old) => ({
                ...old,
                [tabKey]: _orderStatus.href,
                page: config.constants.page,
                pageSize: config.constants.pageSize,
              })}
              className={classNames(
                searchParams?.[tabKey] === _orderStatus.href
                  ? "border-primary-500 text-primary-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={
                searchParams?.[tabKey] === _orderStatus.href ? "page" : undefined
              }
            >
              {_orderStatus.name}
              {_orderStatus.href ? (
                <span
                  className={classNames(
                    searchParams?.[tabKey] === _orderStatus.href
                      ? "bg-primary-100 text-primary-600"
                      : "bg-gray-100 text-gray-900",
                    "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                  )}
                >
                  {numeral(
                    summary?.[
                    _orderStatus?.href
                    ] || 0
                  ).format("0,0")}
                </span>
              ) : null}
            </Link>
          ))}
          {altTabs?.length && (<>
          <div className="h-6 w-px bg-gray-300" />
          {altTabs.map((_orderStatus) => (
            <Link
              key={_orderStatus.name}
              search={(old) => ({
                ...old,
                [tabKey]: _orderStatus.href,
                page: config.constants.page,
                pageSize: config.constants.pageSize,
              })}
              className={classNames(
                searchParams?.[tabKey] === _orderStatus.href
                  ? "border-primary-500 text-primary-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={
                searchParams?.[tabKey] === _orderStatus.href ? "page" : undefined
              }
            >
              {_orderStatus.name}
            </Link>
          ))}
          </>)}
        </nav>
      </div>
    </div>
  );
};

export default HeaderTabs;