import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { ActionButton } from "components/buttons";
import { SearchSelectInput, Avatar } from "components/core";
import { TableComponent, Shimmers } from "components/tables";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";
import { useUrlState, wrapClick, classNames } from "utils";

interface WorkOrderShipmentTableProps {
  isRefetching: boolean;
  loading: boolean;
  refetch: () => void;
  data: any;
  dispatchAction: (id: string, action: any) => () => void;
}

const WorkOrderShipmentTable: FC<WorkOrderShipmentTableProps> = ({
  isRefetching,
  loading,
  refetch,
  data,
  dispatchAction
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const [workOrderStatus, setWorkOrderStatus] = useUrlState("workOrderStatus");

  return (
    <TableComponent
      title={"work orders"}
      refetch={refetch}
      isRefetching={isRefetching}
      loading={loading}
      data={data}
      hasSearch={true}
      renderHeaderItems={() => (
        <div className="mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2">
          <SearchSelectInput
            id="workOrderStatus"
            labelHidden={true}
            options={[
              { label: { title: "Pending" }, value: "Pending" },
              { label: { title: "In Progress" }, value: "InProgress" },
              { label: { title: "Completed" }, value: "Completed" },
            ]}
            label={""}
            placeholder="Select Order Upload Status"
            setFieldValue={(_: any, value: string) =>
              setWorkOrderStatus(value)
            }
            values={{ workOrderStatus }}
          />
        </div>
      )}
      renderColumns={() => (
        <tr>
          <th
            scope="col"
            className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
          >
            Code
          </th>
          <th
            scope="col"
            className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
          >
            Date
          </th>
          <th
            scope="col"
            className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
          >
            District
          </th>
          <th
            scope="col"
            className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
          >
            Success Assignments
          </th>
          <th
            scope="col"
            className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
          >
            Failed Assignments
          </th>
          <th
            scope="col"
            className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
          >
            Total Assignments
          </th>
          <th
            scope="col"
            className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
          >
            Status
          </th>
          <th
            scope="col"
            className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
          >
            Created By
          </th>
          <th
            scope="col"
            className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
          ></th>
        </tr>
      )}
      renderLoader={() => (
        <tr>
          <td className="px-6 py-4 border-b border-gray-200">
            <Shimmers.SingleShimmer />
          </td>
          <td className="px-6 py-4 border-b border-gray-200">
            <Shimmers.SingleShimmer />
          </td>
          <td className="px-6 py-4 border-b border-gray-200">
            <Shimmers.SingleShimmer />
          </td>
          <td className="px-6 py-4 border-b border-gray-200">
            <Shimmers.SingleShimmer />
          </td>
          <td className="px-6 py-4 border-b border-gray-200">
            <Shimmers.SingleShimmer />
          </td>
          <td className="px-6 py-4 border-b border-gray-200">
            <Shimmers.SingleShimmer />
          </td>
          <td className="px-6 py-4 border-b border-gray-200">
            <Shimmers.SingleShimmer />
          </td>
          <td className="px-6 py-4 border-b border-gray-200">
            <Shimmers.DoubleShimmer />
          </td>
          <td className="px-6 py-4 border-b border-gray-200">
            <Shimmers.ActionsShimmer actionsCount={4} />
          </td>
        </tr>
      )}
      renderItem={(item) => (
        <tr
          key={item._id}
          className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
          onClick={wrapClick(
            dispatchAction(item._id, "view-work-order")
          )}
        >
          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
            <div className="font-medium text-gray-900 dark:text-gray-100">
              {item?.code || "N/A"}
            </div>
          </td>
          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
            {moment(item?.createdAt).format(dateFormat)}
          </td>
          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
            {item?.district?.name || "N/A"} (
            {item?.district?.code || "N/A"})
          </td>
          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 text-right border-b border-gray-200">
            {numeral(item?.meta?.totalSuccessAssignments).format(
              "0,0"
            ) ?? "N/A"}
          </td>
          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 text-right  border-b border-gray-200">
            {numeral(item?.meta?.totalFailedAssignments).format(
              "0,0"
            ) ?? "N/A"}
          </td>
          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 text-right  border-b border-gray-200">
            {numeral(item?.meta?.totalAssignments).format("0,0") ??
              "N/A"}
          </td>
          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
            <span
              className={classNames(
                item?.status === "Pending"
                  ? "bg-gray-100 text-gray-800"
                  : "",
                item?.status === "InProgress"
                  ? "bg-amber-100 text-amber-800"
                  : "",
                item?.status === "Completed"
                  ? "bg-green-100 text-green-800"
                  : "",
                "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 10"
                fill="currentColor"
                className="w-1.5 h-1.5"
              >
                <circle
                  fillRule="evenodd"
                  cx="5"
                  cy="5"
                  r="5"
                  clipRule="evenodd"
                />
              </svg>
              <span>{item?.status || "Unknown"}</span>
            </span>
          </td>
          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
            <div className="flex items-center">
              <div className="h-10 w-10 flex-shrink-0">
                <Avatar
                  disabled={true}
                  alt={
                    [
                      (item?.createdBy?.lastName || "")?.trim(),
                      (item?.createdBy?.firstName || "")?.trim(),
                    ]
                      .join(" ")
                      .trim() || "N A"
                  }
                  src={item?.createdBy?.profileImageUrl || ""}
                />
              </div>
              <div className="ml-4">
                <div className="text-gray-900 dark:text-gray-100">
                  {[
                    (item?.createdBy?.lastName || "")?.trim(),
                    (item?.createdBy?.firstName || "")?.trim(),
                  ]
                    .join(" ")
                    .trim() || "N A"}
                </div>
                <div className="text-gray-500 dark:text-gray-400">
                  {item?.createdBy?.phoneNumber || "N/A"}
                </div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
            <div className="space-x-1">
              <ActionButton
                action="view"
                onClick={dispatchAction(item?._id, "view-work-order")}
              />
            </div>
          </td>
        </tr>
      )}
    />
  );
};

export default WorkOrderShipmentTable;