import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import ReadingInfoSummary from "./reading-info-summary";
import ServiceInfoSummary from "./service-info-summary";
import ReplacementMaterialInfoSummary from "./materials-info-summary";
import { FC } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import MeterInfoSummary from "./meter-info-summary";

interface InstallationServiceOrderResolutionViewProps {
  resolution: any;
  result: string;
  oldMeterNumber: string;
  recoveryData?: {
    meterNumber: string;
    meterModel: string;
    meterBrand: string;
    meterImageUrl: string;
    meterCondition: string;
    meterDescription: string;
  };
  type?: "Regularization";
}

const InstallationServiceOrderResolutionView: FC<
  InstallationServiceOrderResolutionViewProps
> = ({ resolution, recoveryData, oldMeterNumber, type }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
              Meter Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about meter installed at the premises
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <ServiceInfoSummary
            data={{ ...resolution?.service, ...resolution, oldMeterNumber }}
          />
          {false && (
            <div className="rounded-md bg-amber-50 border border-amber-100 p-3 mt-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-amber-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-2">
                  <h3 className="text-sm font-medium text-amber-800">
                    Attention needed
                  </h3>
                  <div className="mt-0 text-sm text-amber-700">
                    <p>
                      {" "}
                      Meter assigned is not the same as meter installed on
                      prepayment system. Expected meter{" "}
                      <span className="text-sm font-medium text-amber-800">
                        10130013030
                      </span>{" "}
                      (model: MC041ML045) but got{" "}
                      <span className="text-sm font-medium text-amber-800">
                        1013035353030
                      </span>{" "}
                      (model: MC353ML353)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {type === "Regularization" ? (
        recoveryData ? (
          <div className="pt-6 ">
            <div className="flex justify-between items-center cursor-pointer">
              <div>
                <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
                  Removed Meter Infomation
                </h3>
                <p className="mt-1 text-xs text-gray-500">
                  Details about meter removed from customers premise
                </p>
              </div>
              <div />
            </div>
            <div className="mt-4">
              <MeterInfoSummary data={recoveryData} config={currentConfig} />
            </div>
          </div>
        ) : undefined
      ) : (
        <div className="pt-6 ">
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
                Final Reading Infomation
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Details about final reading taken before removal of existing
                meter
              </p>
            </div>
            <div />
          </div>
          <div className="mt-4">
            <ReadingInfoSummary
              data={{
                readingDate: resolution?.previousReading?.readingDate,
                readingValue: resolution?.previousReading?.readingValue,
                readingImageUrl: resolution?.previousReading?.readingImageUrl,
                notes: resolution?.notes,
              }}
              type={"Final"}
              config={currentConfig}
            />
          </div>
        </div>
      )}
      <div className="pt-6 ">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Initial Reading Infomation
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about initial reading taken after installation of new
              meter
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <ReadingInfoSummary
            data={{
              readingDate: resolution?.currentReading?.readingDate,
              readingValue: resolution?.currentReading?.readingValue,
              readingImageUrl: resolution?.currentReading?.readingImageUrl,
              notes: resolution?.notes,
            }}
            type={"Initial"}
            config={currentConfig}
          />
        </div>
      </div>
      <div className="pt-6 ">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Materials Infomation
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about materials used for the installation
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <ReplacementMaterialInfoSummary
            data={{
              materials: resolution?.materials,
              recoveredMaterials: resolution?.recoveredMaterials,
            }}
          />
        </div>
      </div>
      <div className="pt-6">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
              Results Infomation
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about the outcome of the installation
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="grid grid-cols-3 gap-4">
              {/* <div>
                <span className="block text-sm font-light text-gray-700">
                  Result
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {result || "N/A"}
                </div>
              </div> */}
              <div className="col-span-3">
                <span className="block text-sm font-light text-gray-700">
                  Notes
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {resolution?.notes || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallationServiceOrderResolutionView;
