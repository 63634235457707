import { Fragment, useEffect, useMemo, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  ClockIcon,
  CpuChipIcon,
  SquaresPlusIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { classNames, wrapClick } from "utils";
import useKeyboardJs from "react-use/lib/useKeyboardJs";
import { useZeusSearch } from "./queries";
import { Link, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import lodash from "lodash";

const rawGroups = [
  {
    title: "Installation Service Orders",
    accessor: "installation",
    path: "installations",
    icon: SquaresPlusIcon,
  },
  {
    title: "Replacement Service Orders",
    accessor: "replacement",
    path: "replacements",
    icon: ({ className }: any) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        className={className}
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
        />
        <path
          d="M20 16H13.5L15.5 14"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5 18H20L18 20"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    title: "Backlog Service Orders",
    accessor: "backlog",
    path: "backlog-installations",
    icon: ClockIcon,
  },
  {
    title: "Regularization Service Orders",
    accessor: "regularization",
    path: "regularization-installations",
    icon: ({ className }: any) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        className={className}
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M18 20l2-2m-2 2-2-2m-12 3 M15.5 14l2 2m-2-2-2 2"
        />
      </svg>
    ),
  },
  {
    title: "Meters",
    accessor: "meter",
    path: "meters",
    icon: CpuChipIcon,
  },
];

const SearchContainer = () => {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);

  const [searchPressed] = useKeyboardJs("ctrl > k");
  const [escapePressed] = useKeyboardJs("esc");
  const navigate = useNavigate<LocationGenerics>();
  useEffect(() => {
    if (searchPressed === true) {
      setOpen(true);
    }
  }, [searchPressed]);

  useEffect(() => {
    if (escapePressed === true) {
      setOpen(false);
    }
  }, [escapePressed]);

  const searchResults = useZeusSearch(query);

  const groups = useMemo(() => {
    return {
      data: rawGroups
        ?.map((group) => ({
          ...group,
          items: (searchResults as any)?.[group.accessor]?.data?.items?.map(
            (item: any) => ({ ...item, path: group.path, icon: group.icon })
          ),
        }))
        ?.filter((group) => group?.items?.length > 0),
      loading: !!rawGroups?.find(
        (group) => (searchResults as any)?.[group.accessor]?.loading
      ),
    };
  }, [searchResults]);

  return (
    <>
      <button
        className="mt-5 px-3 cursor-text"
        onClick={wrapClick(() => setOpen(true))}
      >
        <div className="relative mt-1 rounded-md shadow-sm">
          <div
            className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            aria-hidden="true"
          >
            <MagnifyingGlassIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <span className="block w-full rounded-md border-0 py-2 bg-white text-left pl-9 ring-1 ring-inset ring-gray-300 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6">
            Search
          </span>
                  <div className="pointer-events-none absolute inset-y-0 right-1.5 flex items-center justify-center">
                    <span
                      className="hidden sm:block text-gray-500 dark:text-gray-400 text-xs leading-5 py-0.5 px-1.5 border border-gray-300 dark:border-gray-600 rounded-md"
                      style={{ opacity: 1 }}
                    >
                      <span className="sr-only">Press </span>
                      <kbd className="font-sans">
                        <abbr title="Command" className="no-underline">
                          Ctrl
                        </abbr>
                      </kbd>
                      <kbd className="font-sans">+</kbd>
                      <span className="sr-only"> and </span>
                      <kbd className="font-sans">K</kbd>
                      <span className="sr-only"> to search</span>
                    </span>
                  </div>
        </div>
      </button>
      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => setQuery("")}
        appear
      >
        <Dialog className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox
                  onChange={(item: any) =>
                    navigate({
                      to: `/${item.path}`,
                      search: (old) => ({
                        ...old,
                        modal: "view",
                        id: item._id,
                      }),
                    })
                  }
                >
                  {({ activeOption }) => (
                    <>
                      <div className="relative">
                        <MagnifyingGlassIcon
                          className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <Combobox.Input
                          className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                          placeholder="Search..."
                          onChange={(event) => setQuery(event.target.value)}
                          onBlur={() => setQuery("")}
                        />
                        <button
                          onClick={wrapClick(() => setOpen(false))}
                        >
                          <XMarkIcon
                            className="absolute right-4 top-3.5 h-5 w-5 text-gray-400 hover:text-red-400"
                            aria-hidden="true"
                          />
                        </button>
                      </div>

                      {groups?.data?.length ? (
                        <Combobox.Options
                          as="div"
                          static
                          hold
                          className="flex transform-gpu divide-x divide-gray-100"
                        >
                          <div
                            className={classNames(
                              "max-h-[32rem] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4 space-y-3",
                              activeOption ? "sm:h-[32rem]" : "sm:h-[32rem]"
                            )}
                          >
                            {groups?.data?.map?.((group) => (
                              <div key={group.accessor}>
                                <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">
                                  {group.title}
                                </h2>
                                <div className="-mx-2 text-sm text-gray-700">
                                  {group?.items?.map((item: any) => (
                                    <Combobox.Option
                                      as="div"
                                      key={item?._id}
                                      value={item}
                                      className={({ active }) =>
                                        classNames(
                                          "flex cursor-default select-none items-center rounded-md p-2",
                                          active
                                            ? "bg-primary-600 text-white"
                                            : ""
                                        )
                                      }
                                    >
                                      {({ active }) => (
                                        <>
                                          <item.icon
                                            className={classNames(
                                              "h-6 w-6 flex-none",
                                              active
                                                ? "text-white"
                                                : "text-gray-400"
                                            )}
                                            aria-hidden="true"
                                          />
                                          <span className="ml-3 flex-auto truncate">
                                            {item?.code}
                                          </span>
                                          {active && (
                                            <ChevronRightIcon
                                              className="ml-3 h-5 w-5 flex-none text-white"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>

                          {activeOption && (
                            <div className="hidden h-[32rem] w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                              <div className="flex-none flex flex-row items-center p-6 space-x-4">
                                <div className="rounded-md bg-primary-600 p-3">
                                  <activeOption.icon className=" h-8 w-8 text-white" />
                                </div>
                                <div>
                                  <h2 className="mt-3 font-semibold text-gray-900">
                                    #{activeOption.code}
                                  </h2>
                                  <p className="text-sm leading-6 text-gray-500">
                                    {lodash.startCase(activeOption.__typename)}
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-auto flex-col justify-between p-6">
                                {activeOption.__typename === "Meter" ? (
                                  <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Meter Number
                                    </dt>
                                    <dd>{activeOption.code}</dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Brand Code
                                    </dt>
                                    <dd>{activeOption.modelMeta?.brandCode}</dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Brand Name
                                    </dt>
                                    <dd>{activeOption.modelMeta?.brandName}</dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Model Code
                                    </dt>
                                    <dd>{activeOption.modelMeta?.modelCode}</dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Model Name
                                    </dt>
                                    <dd>{activeOption.modelMeta?.modelName}</dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Meter Phase
                                    </dt>
                                    <dd>{activeOption.modelMeta?.phase}</dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Meter Location
                                    </dt>
                                    <dd>
                                      {lodash.startCase(activeOption?.location)}
                                    </dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Meter Status
                                    </dt>
                                    <dd>
                                      {lodash.startCase(activeOption?.status)}
                                    </dd>
                                  </dl>
                                ) : (
                                  <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Name
                                    </dt>
                                    <dd>
                                      {activeOption.contactPerson?.fullName}
                                    </dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Phone
                                    </dt>
                                    <dd>
                                      {activeOption.contactPerson?.phoneNumber}
                                    </dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Address
                                    </dt>
                                    <dd className="truncate">
                                      {activeOption.location?.address}
                                    </dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Community
                                    </dt>
                                    <dd className="truncate">
                                      {activeOption.location?.community}
                                    </dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      SPN
                                    </dt>
                                    <dd className="truncate">
                                      {activeOption.servicePointCode}
                                    </dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Status
                                    </dt>
                                    <dd className="truncate">
                                      {lodash.startCase(activeOption.status)}
                                    </dd>
                                    <dt className="col-end-1 font-semibold text-gray-900">
                                      Meter No.
                                    </dt>
                                    <dd className="truncate">
                                      {activeOption.installationMeterCode}
                                    </dd>
                                    {activeOption.installationMeterSystemSyncStatus && (
                                      <>
                                        <dt className="col-end-1 font-semibold text-gray-900">
                                          Sync Status
                                        </dt>
                                        <dd className="truncate">
                                          {
                                            activeOption.installationMeterSystemSyncStatus
                                          }
                                        </dd>
                                      </>
                                    )}
                                  </dl>
                                )}
                                <Link
                                  type="button"
                                  to={`/${activeOption.path}`}
                                  search={(old) => ({
                                    ...old,
                                    modal: "view",
                                    id: activeOption._id,
                                  })}
                                  className="mt-6 w-full text-center rounded-md bg-primary-600 px-3 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                >
                                  View Details
                                </Link>
                              </div>
                            </div>
                          )}
                        </Combobox.Options>
                      ) : (
                        <div className="px-6 py-14 text-center text-sm sm:px-14">
                          <UsersIcon
                            className="mx-auto h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                          <p className="mt-4 font-semibold text-gray-900">
                            No results found
                          </p>
                          <p className="mt-2 text-gray-500">
                            We couldn’t find anything with that term. Please try
                            again.
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SearchContainer;
